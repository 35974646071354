/* new css */
svg.react-datepicker__triangle {
  display: none;
}
/*  */
.formulation-div{
margin-bottom: 20px;
}
.formulation-div p {
  margin-bottom: 0px !important;  
}
.formulation-div:last-child {
  margin-bottom: 0;
}
p.medication-namep {
  font-weight: 600;
  color: rgb(70 67 67);
}

.addpatient-malecontant.selected {
  border: 2px solid #92b53a !important;
  box-shadow: 0px 0px 5px #92b53a4d;
}
.addpatient-malecontant.selected p {
  color: #72a325 !important;
  font-weight: 600 !important;
}


.fullfile-signdiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 65px;
  gap: 5px;
}
img.kimsig {
  height: 60px;
}
.fullfile-signline {
  flex-grow: 1;
  border-bottom: 2px solid #e6e6e6;
  margin-left: 10px;
  margin-bottom: 40px;
}

.acknowledge-div p {
  margin-bottom: 0 !important;
}
.acknowledge-div input {
  max-width: 125px;
}
.acknowledge-div {
  display: flex;
  align-items: center;
}
.step5form {
  margin: 45px 0 0 0 !important;
}
.exhibit-mainh2 {
  color: var(--text_color);
  font-weight: 600;
  font-size: 24px;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.exhibit-p {
  margin-bottom: 5px !important;
}
.exhibit-maindiv li {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--td_color);
  margin-bottom: 0px;
}
.fullfil-rx {
  color: var(--text_color);
  font-weight: 600;
  font-size: 18px;  
  line-height: 32.74px;
  margin-bottom: 0px;
}
.fullfil-input {
  width: unset !important;
}
ul.inner_ul_row {
  margin: 5px 1px 12px 0px;
}
li.inner-limid {
  margin: 10px 0;
}

ul.inner_ul_row li p {
  margin: 8px 18px 0px 18px;
  display: list-item;
  list-style: circle;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--td_color);
}

ul.inner_ul_row li {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

h2.fulfill-h2 {
  color: var(--text_color);
  font-weight: 600;
  font-size: 18px;
  line-height: 32.74px;
  margin-bottom: 10px;
}

.download-test {
  max-width: 120px;
}

.react-datepicker-popper {
  left: -145px !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container input {
  width: 100%;
  text-align: left;
  padding: 0px 15px;
  height: 52px;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  /* color: var(--td_color); */
  border: 1px solid #eaecf0;
  line-height: 19.1px;
}
.patients-pdfdiv {
  /* display: flex; */
  gap: 10px;
  align-items: center;
  /* justify-content: end; */
  margin-right: 30px;
  float: right;
  padding-bottom: 25px;
}

.patients-pdfdiv .btn {
  height: 40px;
  border: 1px solid var(--text_colorfull);
  background-color: var(--text_colorfull);
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: var(--text_white);
}
.patients-pdfdiv img {
  filter: brightness(0) invert(1);
  margin-right: 6px;
  margin-bottom: 5px;
  height: 14px;
  width: 14px;
}
.patients-pdfdiv .btn:hover {
  border: 1px solid var(--text_colorfull);
  background-color: var(--text_colorfull);
  color: var(--text_white);
}

p.trackingNum {
  font-size: 17px !important;
}

.prescriptioninner {
  /* background-color: #f5f5f5; */
  border-radius: 10px;
  max-width: 100%;
  min-height: 118px;
  /* border: 1px solid rgba(234, 236, 240, 1); */
  margin: 30px 30px;
  padding: 24px;
}

.mandatory-color {
  color: red;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 2px;
}
td.script-td {
  max-width: 185px !important;
}
span.created_date_css {
  font-size: 18px !important;
  margin-left: 8px !important;
}
.practiceName-select select {
  width: 100% !important;
  padding: 10px 10px;
  height: 52px;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  border: 1px solid rgb(236, 236, 236);
  color: #1c1c1c !important;
  max-width: unset;
}

.patients-table th:nth-child(5) {
  min-width: 160px;
}

.patients-table td:nth-child(4) {
  max-width: 155px;
}

.patients-table th:first-child {
  min-width: 160px;
}
.patients-table td.PrescriptionStatus_td {
  min-width: 200px;
}
.prefile-attestation-sig {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  height: 98px;
  width: 100%;
}
input[type="search"]::-webkit-search-decoration:hover,
input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}
.nda-download h2 {
  cursor: pointer;
}
.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PharmacySignup_main .login_section {
  padding: 100px 60px;
  background-color: white;
}
.filterdata1 {
  height: 50vh;
}
.load-div {
  position: fixed;
  background: #0000003b;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 9;
}

.error-div i {
  bottom: 38px !important;
}
.test-results-container-first {
  /* height: 665px; */
  height: auto;
}
td.PrescriptionStatus_td {
  cursor: default !important;
}
td.PrescriptionStatus_td p.Ordered {
  cursor: default !important;
}

.signup_main .login_section {
  padding: 35px 60px 0px 60px;
}
.password-div {
  position: relative;
}
.password-div i {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #1c1c1c;
  transition: color 0.3s ease;
  bottom: 16px;
}

.prescription-table td {
  max-width: 100px;
  word-wrap: break-word;
}
.patients-table td {
  max-width: 220px;
  word-wrap: break-word;
}
.dashboard-table td {
  max-width: 200px;
  word-wrap: break-word;
}
.patients-tabletd {
  width: 50px;
}
tr.no_data_td {
  height: 200px;
}
tr.no_data_td td {
  text-align: center;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 500;
}
.provider-deleted-p {
  padding: 455px 0px 1px 30px;
  color: var(--text_color);
  font-size: 22px;
  font-weight: 600 !important;
}
.error {
  color: #ff0000 !important;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 0 !important;
}
.patient-note {
  color: #667085 !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 0 !important;
  display: inline-grid;
}
.invite-error {
  text-align: unset !important;
}
.Addpatient-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-container {
  position: relative;
  width: 350px;
}
.search-icon {
  position: absolute;
  top: 13px;
  left: 15px;
  height: 12px;
  width: 12px;
  color: var(--td_color);
}
.search-container input {
  width: 100%;
  padding: 10px 10px 10px 45px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  height: 40px;
  width: 350px;
  cursor: pointer !important;
}
.search-con {
  width: unset;
}
.sign-sigdiv {
  margin-top: 0 !important;
}
p.login-or {
  margin: 10px 0 0px 0;
  text-align: center;
}
.sign-btn {
  width: 110px !important;
}
.subbtn.sign-sigdiv a {
  text-decoration: none;
  color: #799a26;
}
td.pre-qty {
  width: 165px;
  padding-left: 28px !important;
}
.drop_select1 {
  position: relative;
}
.route-arrowimage {
  transform: rotate(180deg);
}
p.pro-all {
  margin-bottom: 0px;
}
/* .pre-sta p {
  width: 140px;
} */
.DateRangePickerInput_arrow {
  margin-right: 10px;
}
.invite_profile-content.errors-div {
  height: 360px !important;
}
p.Already-p {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 10px 0;
}
a.Already-link {
  color: #92b53a;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}
section.center_section.profile-section.pharmacy-center-sec {
  margin-bottom: 100px;
}

/* Practitioner Information */
canvas.sigCanvas {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  height: 98px;
  width: 100%;
}

.prac_main {
  margin-bottom: 120px;
}
.GetHairlogo {
  width: 248px;
  height: 65px;
  display: block;
  margin: 20px auto;
}
.prac-topbar {
  background-color: var(--top_background);
  padding: 15px;
}
.stepper-div {
  display: flex;
  width: 1165px;
  flex-direction: column;
  margin: 65px auto 0px auto;
  border: 1px solid var(--border-color);
  padding: 25px 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #ffff;
}
.stepper-div .MuiStepLabel-label.MuiStepLabel-active {
  font-weight: 500;
  color: var(--text_color);
  font-size: 20px;
  line-height: 27.28px;
}
.stepper-div .MuiStepLabel-label {
  color: var(--text_color);
  font-weight: 500;
  color: var(--text_color);
  font-size: 20px;
  line-height: 27.28px;
}
.stepper-div .MuiStepIcon-root.MuiStepIcon-active {
  color: var(--primery_color);
}
.MuiStepIcon-root {
  color: #eff0f6;
}
.stepper-div .MuiSvgIcon-root {
  width: 1.5em;
  height: 1.5em;
}
.stepper-div .MuiStepConnector-lineHorizontal {
  border-top-width: 5px !important;
  border-radius: 50px;
}
.MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.MuiStepConnector-completed
  .MuiStepConnector-lineHorizontal {
  border-color: var(--primery_color);
  margin-top: 5px;
}
.MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.MuiStepConnector-active
  .MuiStepConnector-lineHorizontal {
  border-color: var(--primery_color);
  margin-top: 5px;
}
.MuiStepIcon-root.MuiStepIcon-completed {
  color: var(--primery_color) !important;
}
.stepper-div .MuiStepConnector-line {
  border-color: #eff0f6;
  margin-top: 5px;
}
span.MuiStepLabel-root.MuiStepLabel-horizontal.Mui-disabled.MuiStepLabel-alternativeLabel
  .MuiStepIcon-text {
  fill: var(--td_color);
}
span.MuiStepLabel-root.MuiStepLabel-horizontal.Mui-disabled.MuiStepLabel-alternativeLabel
  .MuiStepIcon-root {
  color: #eff0f6 !important;
}
.step1_div {
  width: 1165px;
  margin: 0 auto;
  border: 1px solid var(--border-color);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.step1_contant h2 {
  color: var(--text_color);
  font-weight: 600;
  font-size: 24px;
  padding: 15px;
  text-align: center;
  line-height: 32.74px;
  background: var(--top_background);
  text-transform: uppercase;
  margin-bottom: 0;
}
.prac-formdiv {
  padding: 20px;
  background-color: #fff;
}
.prac-formdiv .login-input-bx {
  max-width: unset;
}
.prac-formdiv .sign-multidiv {
  gap: 25px;
}
.step1_div2 {
  width: 1165px;
  margin: 20px auto;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}
.step1_contant2 h2 {
  color: var(--text_color);
  font-weight: 600;
  font-size: 24px;
  padding: 15px;
  text-align: center;
  line-height: 32.74px;
  background: var(--top_background);
  text-transform: uppercase;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-bottom: 0;
  border-bottom: 1px solid var(--border-color);
}

.select_container {
  display: flex;
  gap: 15px;
}

.select_container > * {
  flex: 1;
  width: 100%;
}
.select_container select {
  width: 100%;
  text-align: left;
  padding: 0px 15px;
  height: 52px;
  font-size: 14px;
  border: none;
  border-radius: 10px;
  color: var(--td_color);
  border: 1px solid #eaecf0;
  line-height: 19.1px;
  background-color: transparent;
}
.step1-btndiv {
  display: flex;
  justify-content: end;
  margin: 30px 0px 10px 0px;
}
.step1-btndiv .color_btn {
  width: 102.39px;
  height: 46px;
  border-radius: 8px;
  padding: 10px 16px 10px 16px;
  background-color: var(--primery_color);
  border: 1px solid var(--primery_color);
  font-size: 16px;
  font-weight: 600;
  line-height: 21.82px;
  color: var(--text_white);
}
.prac-step2formdiv {
  padding: 25px 35px;
  background-color: #fff;
}
.prac-step2formdiv p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--td_color);
  margin-bottom: 25px;
}
.step2-span {
  padding: 2px 0px 20px 15px;
}
.step2-span p {
  margin-top: 0 !important;
}
.step2-spandiv span {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--td_color);
}
.step2-span span {
  font-weight: bold;
  display: inline-block;
  padding-left: 15px;
  position: relative;
  color: var(--td_color);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.step2-span span:before {
  content: "\2022";
  position: absolute;
  left: 0;
}
textarea {
  width: 100%;
  height: 98px;
  border: 1px solid #eaecf0;
  border-radius: 10px;
}
.step2form {
  margin: 45px 0;
}
.step1-btndiv .btn {
  width: 111px;
  height: 46px;
  border-radius: 8px;
  padding: 10px 16px 10px 16px;
  background-color: transparent;
  border: 1px solid var(--primery_color);
  color: var(--primery_color);
  font-size: 16px;
  font-weight: 600;
  line-height: 21.82px;
  margin-right: 10px;
}
.sign_span {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  display: flex;
  justify-content: end;
  color: var(--text_colorfull);
  cursor: pointer;
}
.clear-val {
  position: relative;
}
.clear-val .error {
  position: absolute;
  top: 0;
}
.signature_style {
  border-radius: 10px;
}
.HIPAA_Agreement {
  padding: 25px 35px;
  background-color: #fff;
}
.HIPAA_Agreement p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--td_color);
  margin-bottom: 25px;
}

.HIPAA_Agreement p:first-child {
  margin-bottom: 0 !important;
}
.HIPAA_Agreement_pdiv .serial-list {
  counter-reset: serial-counter;
  list-style: none;
}
.HIPAA_Agreement_pdiv .serial-list li {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--td_color);
  margin-bottom: 25px;
  list-style: auto;
}
.business-topdiv {
  padding: 25px 35px 0px 35px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.business-topdiv p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--td_color);
  margin-bottom: 10px;
  max-width: 96%;
}
.business-contantdiv {
  padding: 15px 35px;
  background-color: #fff;
}
.business-contantdiv p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--td_color);
  margin-bottom: 5px;
}
input.business-topinput {
  width: 8.5%;
  border: none;
  border-bottom: 1px solid #667085;
  margin: 10px 10px 10px 10px;
}

input.business-topinput:focus {
  outline: none;
}
input.business-topinput1 {
  width: 25%;
  border: none;
  border-bottom: 1px solid #667085;
  margin: 0px 10px 10px 10px;
  font-size: 15px;
}
input.business-topinput1:focus {
  outline: none;
}
.business-topdivp1 {
  /* display: flex;
  gap: 10px; */
  align-items: center;
  flex-wrap: wrap;
}
.business-topdivp2 {
  display: flex;
  gap: 10px;
  align-items: center;
}
.bcafli {
  margin-bottom: 0 !important;
}
.bcaseli {
  margin-bottom: 0 !important;
}
.bca-bottomdiv h2 {
  color: var(--text_color);
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0;
  margin-bottom: 25px;
  /* text-align: center; */
}
.single-div {
  max-width: 530px;
}
.bca-bottomdiv .step2form {
  margin: 35px 0 45px 0;
}
.facility-h2 {
  margin-top: 25px;
}
.bca-sig {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  height: 98px;
  width: 100%;
}
.bca-sig img {
  height: 105px;
  padding: 0px 10px 10px 10px;
}
.bca-bottomdiv hr {
  border-color: #a8acb5;
}
/* .bca-bottomdiv .step2form{
margin-top: 0 !important;
} */
span.business-topdivspan {
  /* margin: 0 10px; */
}
.Attestation_div {
  padding: 25px 35px;
  background-color: #fff;
}

.checkbox-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.checkbox-list li {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.checkbox-list input[type="checkbox"] {
  margin-top: 5px;
  height: 15px;
  width: 15px;
  border-radius: 5px;
  border: 1px solid var(--td_color);
}

.checkbox-list label {
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--td_color);
  margin-bottom: 25px;
}

.Attestation_div p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--td_color);
  margin-bottom: 25px;
}

.stepper-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.stepper-content {
  background: #f8faf3;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 872px;
  height: 282px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}
.stepper-content p {
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: center;
  color: var(--pop_text);
  width: 635px;
  height: 76.04px;
}
.stepper-content button {
  width: 86px;
  height: 39px;
  border-radius: 8px;
  padding: 10px 16px 10px 16px;
  background-color: var(--primery_color);
  border: 1px solid var(--primery_color);
  font-size: 16px;
  font-weight: 600;
  line-height: 19.1px;
  color: var(--text_white);
}
.stdropdown-container {
  border: none !important;
}
.stsearch-box input {
  height: 52px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  padding: 10px !important;
}
.login-input-bx svg {
  right: 3px;
  top: 8px;
}
.stdropdown-tool svg {
  fill: #999999 !important;
}
/* Add new patients */
.Addpatient-main {
  max-width: 1165px;
  margin: 50px auto 0px auto;
  padding-bottom: 75px;
  overflow: auto;
}
.Addpatient_row {
  padding: 10px;
}
.Addpatient-firstcard {
  margin-top: 25px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}
.Addpatient-firstcard-top {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: var(--text_colorfull);
  color: var(--text_white);
}
form.tricho-test-form {
  padding: 25px 30px;
}
.Addpatient-firstcard-top p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.addpatient-dobleinput {
  display: flex;
  gap: 25px;
  margin-top: 15px;
}
.addpatient-dobleinput .login-input-bx {
  flex: 1;
  width: 50%;
}
.Addpatient-firstcard-top input.addpatient_input {
  height: 40px;
  width: 241px;
}
input.addpatient_input {
  width: 100%;
  text-align: left;
  padding: 0px 15px;
  height: 52px;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  /* color: var(--td_color); */
  border: 1px solid #eaecf0;
  line-height: 19.1px;
}
.Addpatient-firstcard-topright {
  display: flex;
  align-items: center;
  gap: 10px;
}
.Addpatient-firstcard-top h1 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.addpatient-checkboxdiv {
  display: flex;
  align-items: center;
  gap: 10px;
}

.addpatient-checkboxdiv p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 0;
  color: var(--td_color);
}
.addpatient-checkboxdiv input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-color: var(--lite-checkoxborder);
}
.addpatient-checkboxmain {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 18px;
}
.addpatient_textarea {
  flex: 1 1;
  width: 50%;
}
.addpatient_textarea textarea {
  border: 1px solid var(--lite-checkoxborder);
  border-radius: 6px;
  padding: 10px 15px 10px 15px;
  height: 120px;
  font-size: 14px !important;
}

.addpatient-Ethnicitydiv {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 6px 0;
  min-width: 25%;
}

.addpatient-Ethnicitymain {
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 1fr 1fr;
}

.addpatient-Ethnicitydiv input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-color: var(--lite-checkoxborder);
  cursor: pointer;
}
.addpatient-Ethnicitydiv p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 0;
  color: var(--td_color);
}
.addpatient-Ethnicitymaindiv {
  margin: 15px 0 25px 0;
  width: 100%;
  max-width: 100%;
}

.Addpatient-secondcard {
  margin-top: 25px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}
.Addpatient-secondcard-top {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: var(--text_colorfull);
  color: var(--text_white);
}
.Addpatient-secondcard-top h1 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}
.Addpatient-secondcontainer {
  padding: 25px 30px;
}
.addpatient-contant h1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 10px 0 5px 0;
}
.Alopecia-main {
  display: flex;
  align-items: center;
  gap: 40px;
}
.Alopecia-div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 6px 0;
}
.Alopecia-div input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-color: var(--lite-checkoxborder);
}
.Alopecia-div p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 0;
  color: var(--td_color);
}
.Alopecia-main.diseases-div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0px;
}
.addpatient-contant p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--td_color);
  margin-bottom: 0px;
  word-wrap: break-word;
  max-width: 201px;
}
.addpatient-malemain {
  border: 1px solid var(--border-color);
  background-color: var(--lite-popbackground);
  height: 133px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  gap: 5px;
}
.addpatient-malecontant.selected {
  border: 1px solid var(--pop-border);
}
.addpatient-malecontant {
  background-color: var(--text_white) !important;
  height: 92px;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 5px;
}
.addpatient-malecontant img {
  width: 35px;
  height: 41px;
}
.addpatient-malecontant p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--sub_text_color);
  margin-bottom: 0;
}
#addpatient-femalep {
  margin-top: 15px;
  margin-bottom: 10px;
}
#addpatient-malep {
  margin-bottom: 10px;
}

.Directfamily-div {
  margin-top: 25px;
}
.addpatient-tocontant {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-top: 10px;
}
.addpatient-tocontant p {
  margin: 10px 0 5px 0;
}
.diseases-div .Alopecia-div {
  width: 260px;
}
.diseases-div {
  gap: unset;
}
.Hypersensitivity-div .Alopecia-div {
  width: 150px;
}
.Alopecia-main.Hypersensitivity-div {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0px;
}
.Cholesterol-main {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-gap: 12px;
  margin-top: 12px;
}

.FemaleOnly-div {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  height: 24px;
  background-color: var(--text_colorfull);
  color: var(--text_white);
  border-radius: 5px;
  margin-top: 20px;
}
.FemaleOnly-div h1 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.Addpatient-btn {
  width: 125px;
  height: 48px;
  border-radius: 8px;
  padding: 10px 16px 10px 16px;
  background-color: var(--primery_color);
  border: 1px solid var(--primery_color);
  font-size: 16px;
  font-weight: 600;
  line-height: 21.82px;
  color: var(--text_white);
  margin: 25px 0;
}

.validate-address-btn {
  width: 180px;
  height: 48px;
  border-radius: 8px;
  padding: 10px 16px 10px 16px;
  background-color: var(--primery_color);
  border: 1px solid var(--primery_color);
  font-size: 16px;
  font-weight: 600;
  line-height: 21.82px;
  color: var(--text_white);
  margin: 25px 0;
}

.Addpatient-btn:disabled {
  opacity: 0.8;
}

.Addpatient-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Addpatient-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 872px;
  height: 356px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Addpatient-content .close-img {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 20px;
  color: var(----text_color);
  width: 25px;
  height: 25px;
}
.Addpatient-popupcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Addpatient-popupcontent img {
  width: 146px;
  height: 156px;
}
.Addpatient-popupcontent h1 {
  font-size: 25px;
  font-weight: 600;
  line-height: 38.4px;
  margin-bottom: 0;
  color: var(--text-darkblack);
}
.Addpatient-spindiv {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 50px;
}
.Addpatient-spindiv p {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: var(--sub_text_color);
  margin-bottom: 0;
}
.Addpatient-lod {
  height: 289px;
}
.spinner-border {
  width: 3rem;
  height: 3rem;
  color: var(--text_colorfull);
}
.Alopecia-div input:checked {
  accent-color: var(--checkbox-color);
}
.addpatient-checkboxdiv input:checked {
  accent-color: var(--checkbox-color);
}
.addpatient-Ethnicitydiv input:checked {
  accent-color: var(--checkbox-color);
}
.addpatient-checkboxdiv input:checked {
  accent-color: var(--checkbox-color);
}

/* profile */
.profile-main {
  max-width: 1165px;
  margin: 0px auto 0px auto;
  padding-bottom: 75px;
  overflow: auto;
}
.profile-maindiv1 {
  margin-top: 25px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}
.profile-section .login_form {
  margin: 80px auto 25px auto !important;
}
.profile-top i {
  width: 28px;
  height: 28px;
  background-color: var(--primery_color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text_white);
  border-radius: 5px;
}
.profile-top {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 18px 20px;
  border-bottom: 1px solid var(--border-color);
}
.profile-top h1 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}
.profile-contantmain1 {
  display: flex;
  width: 100%;
  padding: 15px 5px 5px 5px;
  align-items: center;
}
.profile-div1contant {
  margin: 20px 30px 20px 20px;
}
.profile-agremain {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: white;
}
.profile-agretop {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 18px 20px;
  border-bottom: 1px solid var(--border-color);
}
.profile-agretop h1 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.agre-div {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.agre-div img {
  cursor: pointer;
  padding-top: 1px;
}
.agre-div h2 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  color: var(--text_color);
}
.arg-downloaddiv {
  display: flex;
  gap: 10px;
  height: 40px;
  border: 1px solid var(--text_colorfull);
  align-items: center;
  border-radius: 8px;
  background-color: var(--text_colorfull);
}
.arg-downloaddiv .btn {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: var(--text_white);
}
.arg-downloaddiv img {
  filter: brightness(0) invert(1);
  margin-right: 6px;
  margin-bottom: 5px;
  height: 14px;
  width: 14px;
}
.arg-downloaddiv .btn:hover {
  border: 1px solid var(--text_colorfull);
  background-color: var(--text_colorfull);
  color: var(--text_white);
}
.profile-agrecontant {
  margin: 20px 30px 20px 20px;
}
.first-part {
  flex: 0 0 25%;
}
.second-part {
  flex: 0 0 75%;
}
.profile-maindiv2 {
  margin-top: 25px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: white;
}
.profile-maindiv3 {
  margin-top: 25px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: white;
}

.badge {
  background-color: var(--badges-color);
  color: var(--text_white);
  padding: 3px 5px;
  border-radius: 50%;
  font-size: 12px;
  position: absolute;
  top: 10px;
  left: 490px;
}

.download-icon {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  gap: 2px;
  cursor: pointer;
}
.download-icon img {
  margin-bottom: 0;
  margin-right: 3px;
  width: 14px;
  height: 14px;
}
.download-icon p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #667085;
  margin-top: 3px;
}

.refill-red {
  border: 1px solid #ff0000;
  background-color: #ffc4c4;
}
.refill-red span {
  color: #ff0000;
}
.refill-red path {
  fill: #ff0000;
}

.Store_section.m-0.mt-4.TestResultsAlert svg.cross-icon path {
  stroke: #87af41;
}

.Store_section.m-0.mt-4.TestResultsAlert.refill-red svg.cross-icon path {
  stroke: red;
}
.DateRangePicker_picker.DateRangePicker_picker_1.DateRangePicker_picker__directionLeft.DateRangePicker_picker__directionLeft_2 {
  left: -225px !important;
}
.TestResultsAlert span {
  position: relative;
}
.TestResultsAlert span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 100%;
  height: 2px;
  background-color: #d7e1b7;
}
.refill-red span::after {
  display: none;
}
.refill-check {
  /* margin-bottom: 18px; */
  /* margin: 19px 145px 20px 145px; */
  /* margin: 0 auto 18px auto;
  max-width: 1597px;
  width: 100% !important; */
}
.refill-checkdiv {
  display: flex;
  gap: 10px;
  padding: 0 0 20px 10px;
}
.Prescr-btndiv {
  display: flex;
  justify-content: end;
  gap: 15px;
  /* margin-top: 25px; */
  margin-right: 30px;
}
.Prescr-btndiv .btn1 {
  border-radius: 8px;
  padding: 12px 10px;
  background-color: var(--primery_color);
  border: 1px solid var(--primery_color);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: var(--text_white);
  display: flex;
  justify-content: center;
  align-items: center;
}
.Prescr-btndiv .btn2 {
  border-radius: 8px;
  padding: 12px 20px;
  background-color: transparent;
  color: var(--primery_color);
  border: 1px solid var(--primery_color);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pre-redfillsbtndiv .form-select {
  max-width: 240px;
  border: 1px solid var(--primery_color);
  background-color: #ffff;
  color: var(--primery_color);
  height: 39px;
  border-radius: 10px;
}
.refilesnew {
  width: 125px;
}
.refilesnew .form-select {
  max-width: 125px;
}
.pre-redfillsbtndiv option {
  color: #667085;
}
.sign-multigrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-gap: 15px;
}
.sign-multigrid .login-input-bx {
  width: 100%;
}
.addpatient-contant.medica-main .Alopecia-div {
  min-width: 215px;
  max-width: 215px;
}
.Alopecia-main.medica-input {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0px;
}

/* 
.popup-fre-content h3 {
  border-radius: 8px;
  border: 1px solid #92B53A;
  background-color: #F5F7F2;
  color: #92B53A;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 240px;
  height: 39px;
  padding: 10px 20px;
  font-family: 'Nunito Sans' !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
  cursor: pointer;
}

ul.FrequencyOptions-list li {
  list-style: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: #667085;
  border-bottom: 1px solid #6670851A;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  
}

.popup-fre-content {
  position: relative;
}
ul.FrequencyOptions-list {
  padding: 14px 20px;
  border: 1px solid #92B53A4D;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  width: 240px;
  background: white;
  max-height: 162px;
  overflow: auto;
}
ul.FrequencyOptions-list li:last-child {
  border: none;
} */

@media (min-width: 768px) and (max-width: 1023px) {
  .prac_main {
    overflow: hidden;
  }
  .prac-topbar {
    width: 100%;
    height: 135px;
    overflow: hidden;
  }
  .stepper-div {
    width: 100%;
  }
  .step1_div {
    width: 100%;
  }
  .step1_div2 {
    width: 100%;
  }
  .makeStyles-root-1 {
    width: unset !important;
    margin: 0 15px;
  }
  canvas.signature_style {
    width: 100%;
    height: 98px;
  }
  .stepper-content {
    width: 100%;
    margin: 0 20px;
  }
  .makeStyles-root-3 {
    width: 100%;
    padding: 0 10px 0 10px;
  }
  .addpatient-Ethnicitymain {
    grid-template-columns: 1.2fr 1fr 1fr;
  }
  .Alopecia-main.Hypersensitivity-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: unset;
  }
  .medica-main .Alopecia-main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: unset;
  }

  .Alopecia-main.diseases-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: unset;
  }
  .scalp-main .Alopecia-main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: unset;
  }
  .Addpatient-main {
    width: 100%;
    overflow-x: hidden;
    margin: 0px auto 0px auto;
  }
  .Addpatient-content {
    margin: 10px;
  }
  .Addpatient-popupcontent h1 {
    text-align: center;
  }
  .badge {
    top: 112px;
    left: 80px;
  }
  .refill-checkdiv {
    padding: 0 0 20px 0px;
  }
}

@media (max-width: 767px) {
  .profile-section .login_form {
    margin: 30px auto 30px auto !important;
  }

  .Addpatient-main {
    padding-bottom: 30px;
  }

  .login_form.Profile_row_new {
    padding: 0 !important;
  }

  .arg-downloaddiv {
    min-width: 115px;
  }
  .agre-div {
    gap: 10px;
  }

  .prac_main {
    overflow: hidden;
  }
  .prac-topbar {
    width: 100%;
    height: 135px;
    overflow: hidden;
  }
  .stepper-div {
    width: 100%;
  }
  .step1_div {
    width: 100%;
  }
  .step1_div2 {
    width: 100%;
  }
  .makeStyles-root-1 {
    width: unset !important;
    margin: 0 10px;
  }
  canvas.signature_style {
    width: 100%;
    height: 98px;
  }
  .stepper-content {
    width: 100%;
    margin: 0 20px;
  }
  .makeStyles-root-3 {
    width: 100%;
    padding: 0 10px 0 10px;
  }
  .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.MuiPaper-elevation0 {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    gap: 65px;
  }

  .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.MuiPaper-elevation0
    .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel
    span {
    display: flex;
    flex-direction: row;
    position: relative;
    margin: 0;
  }

  .MuiStepConnector-alternativeLabel {
    top: 12px;
    left: 0;
    right: 0;
    position: absolute;
    transform: rotate(90deg);
  }

  span.MuiStepLabel-root.MuiStepLabel-horizontal.MuiStepLabel-alternativeLabel {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel:nth-child(2)
    .MuiStepConnector-alternativeLabel {
    top: -36px;
    left: -2px;
    right: 0;
    position: absolute;
    width: 55px;
  }

  .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel:nth-child(3)
    .MuiStepConnector-alternativeLabel {
    top: -36px;
    left: -2px;
    right: 0;
    position: absolute;
    width: 55px;
  }

  .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel:nth-child(4)
    .MuiStepConnector-alternativeLabel {
    top: -36px;
    left: -2px;
    right: 0;
    position: absolute;
    width: 55px;
  }
  .sign-multidiv {
    gap: unset;
    flex-direction: column;
  }
  .sign-multidiv .login-input-bx {
    width: 100%;
  }
  .select_container {
    flex-direction: column;
  }
  .stepper-content p {
    width: 350px;
  }
  .stepper-content button {
    margin-top: 20px;
  }
  .addpatient-Ethnicitymain {
    grid-template-columns: 1.2fr;
  }
  .Addpatient-firstcard-top {
    align-items: start;
    flex-direction: column;
  }
  .Addpatient-firstcard-topright {
    align-items: start;
    gap: 5px;
    flex-direction: column;
    margin-top: 10px;
  }
  .addpatient-dobleinput {
    flex-direction: column;
  }
  .addpatient-dobleinput .login-input-bx {
    width: 100%;
  }
  .addpatient_textarea {
    width: unset;
  }
  .addpatient_textarea textarea {
    width: 100%;
  }
  .alo-main .Alopecia-main {
    display: unset;
  }
  .Directfamily-div .Alopecia-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: unset;
  }
  .Directfamily-div .hair-falling {
    grid-template-columns: 1fr;
  }
  .Alopecia-main.Hypersensitivity-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: unset;
  }
  .medica-main .Alopecia-main {
    display: grid;
    grid-template-columns: 1fr;
    gap: unset;
  }
  .Alopecia-main.diseases-div {
    display: grid;
    grid-template-columns: 1fr;
    gap: unset;
  }
  .Cholesterol-main .Alopecia-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: unset;
  }
  .scalp-main .Alopecia-main {
    display: grid;
    grid-template-columns: 1fr;
    gap: unset;
  }
  .Addpatient-main {
    width: 100%;
    overflow-x: hidden;
    margin: 0px auto 0px auto;
  }
  .addpatient-contant h1 {
    margin: 10px 30px 6px 1px;
  }
  .addpatient-tocontant {
    align-items: unset;
    gap: unset;
    flex-direction: column;
  }
  .login-input-bx label {
    font-weight: 400;
  }
  .addpatient-tocontant p {
    margin: 0px 0 20px 0;
  }
  .addpatient-malemain {
    height: 425px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .add-femainmain {
    height: 525px;
  }
  .Addpatient-content {
    margin: 10px;
  }
  .Addpatient-popupcontent h1 {
    text-align: center;
  }
  .badge {
    top: 112px;
    left: 80px;
  }
}

@media (max-width: 479px) {
  .prac_main {
    overflow: hidden;
  }
  .prac-topbar {
    width: 100%;
    height: 135px;
    overflow: hidden;
  }
  .stepper-div {
    width: 100%;
  }
  .step1_div {
    width: 100%;
  }
  .step1_div2 {
    width: 100%;
  }
  .makeStyles-root-1 {
    width: unset !important;
    margin: 0 10px;
  }
  canvas.signature_style {
    width: 100%;
    height: 98px;
  }
  .stepper-content {
    width: 100%;
    margin: 0 20px;
  }
  .makeStyles-root-3 {
    width: 100%;
    padding: 0 10px 0 10px;
  }
  .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.MuiPaper-elevation0 {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    gap: 65px;
  }

  .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.MuiPaper-elevation0
    .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel
    span {
    display: flex;
    flex-direction: row;
    position: relative;
    margin: 0;
  }

  .MuiStepConnector-alternativeLabel {
    top: 12px;
    left: 0;
    right: 0;
    position: absolute;
    transform: rotate(90deg);
  }

  span.MuiStepLabel-root.MuiStepLabel-horizontal.MuiStepLabel-alternativeLabel {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel:nth-child(2)
    .MuiStepConnector-alternativeLabel {
    top: -36px;
    left: -2px;
    right: 0;
    position: absolute;
    width: 55px;
  }

  .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel:nth-child(3)
    .MuiStepConnector-alternativeLabel {
    top: -36px;
    left: -2px;
    right: 0;
    position: absolute;
    width: 55px;
  }

  .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel:nth-child(4)
    .MuiStepConnector-alternativeLabel {
    top: -36px;
    left: -2px;
    right: 0;
    position: absolute;
    width: 55px;
  }
  .sign-multidiv {
    gap: unset;
    flex-direction: column;
  }
  .sign-multidiv .login-input-bx {
    width: 100%;
  }
  .select_container {
    flex-direction: column;
  }
  .stepper-content p {
    width: 350px;
  }
  .stepper-content button {
    margin-top: 20px;
  }
  .addpatient-Ethnicitymain {
    grid-template-columns: 1.2fr;
  }
  .Addpatient-firstcard-top {
    align-items: start;
    flex-direction: column;
  }
  .Addpatient-firstcard-topright {
    align-items: start;
    gap: 5px;
    flex-direction: column;
    margin-top: 10px;
  }
  .addpatient-dobleinput {
    flex-direction: column;
  }
  .addpatient-dobleinput .login-input-bx {
    width: 100%;
  }
  .addpatient_textarea {
    width: unset;
  }
  .addpatient_textarea textarea {
    width: 100%;
  }
  .alo-main .Alopecia-main {
    display: unset;
  }
  .Directfamily-div .Alopecia-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: unset;
  }
  .Directfamily-div .hair-falling {
    grid-template-columns: 1fr;
  }
  .Alopecia-main.Hypersensitivity-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: unset;
  }
  .medica-main .Alopecia-main {
    display: grid;
    grid-template-columns: 1fr;
    gap: unset;
  }
  .Alopecia-main.diseases-div {
    display: grid;
    grid-template-columns: 1fr;
    gap: unset;
  }
  .Cholesterol-main .Alopecia-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: unset;
  }
  .scalp-main .Alopecia-main {
    display: grid;
    grid-template-columns: 1fr;
    gap: unset;
  }
  .Addpatient-main {
    width: 100%;
    overflow-x: hidden;
    margin: 0px auto 0px auto;
  }
  .addpatient-contant h1 {
    margin: 10px 30px 6px 1px;
  }
  .addpatient-tocontant {
    align-items: unset;
    gap: unset;
    flex-direction: column;
  }
  .login-input-bx label {
    font-weight: 400;
  }
  .addpatient-tocontant p {
    margin: 0px 0 20px 0;
  }
  .addpatient-malemain {
    height: 425px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .add-femainmain {
    height: 525px;
  }
  .Addpatient-content {
    margin: 10px;
  }
  .Addpatient-popupcontent h1 {
    text-align: center;
  }
  .badge {
    top: 112px;
    left: 80px;
  }
  .signup_main .login_section {
    padding: 35px 20px 0px 20px;
  }
}
