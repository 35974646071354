@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lexend:wght@100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --primery_color: #92b53a;
  --text_color: #1c1c1c;
  --td_color: #667085;
  --sub_text_color: #667085;
  --danger_color: red;
  --border-color: #eaecf0;
  --text_colorfull: #92b53a;
  --text_white: #ffffff;
  --top_background: #f2f5ea;
  --pop_text: #1a1a1a;
  --pop-border: #92b53a4d;
  --pop-litered: #ffc4c4;
  --text-liteyellow: #f9f2d9;
  --text-darkyellow: #cb9e00;
  --lite-background: #f5f7f1;
  --lite-textcolor: #787878;
  --lite-checkoxborder: #e4e8f0;
  --lite-popbackground: #fbfcfd;
  --text-darkblack: #1a1a1a;
  --chat-bg: #eaecf0;
  --text-invalid: #364254;
  --invite-popup: #f8faf3;
  --checkbox-color: #76a109;
  --badges-color: #ffc635;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
}



.right-side .filterdata .profile-content .provider-data {
  max-height: calc(100vh - 50vh);
  overflow: auto;
}


.card-header.preseinner button.btn {
  height: 40px;
  border: 1px solid var(--text_colorfull);
  background-color: var(--text_colorfull);
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: var(--text_white);
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: 30px;
}
.card-header.preseinner p.trackingNum {
  margin-left: auto;
}
.card-header.preseinner button.btn img {
  width: 20px;
}




.primery_btn {
  background-image: linear-gradient(
    90deg,
    rgba(119, 151, 37, 1) 0%,
    rgba(146, 181, 58, 1) 100%
  );
  height: 42px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 16px;
  border: none;
  color: white;
  min-width: 100px;
  transition: all ease-in-out 0.3s;
}
.Practices-main .table tr td:nth-child(2) {
  max-width: 300px;
}
.status-title {
  text-transform: capitalize;
}
.center_section {
  /* margin-top: 80px; */
  min-height: calc(100vh - 187px);
}
.main-pre {
  margin-bottom: 100px;
}
/* .setting-botdiv {
  margin-bottom: 120px;
} */
.change_password_card-settings.setting_solution.mb-4.setting-botdiv {
  margin-bottom: 150px !important;
}
.react-responsive-modal-container.react-responsive-modal-containerCenter {
  backdrop-filter: blur(2px);
  display: flex;
}
.prescriptioninner textarea {
  padding: 10px;
}
.react-responsive-modal-container {
  overflow-y: hidden !important;
  padding: 10px;
  max-height: 100vh !important;
}
.react-responsive-modal-modal {
  max-width: 700px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
  display: flex !important;
}
.container_box {
  max-width: 1597px;
  margin: auto;
}
section.center_section.dashboard-section {
  min-height: calc(100vh - 301px) !important;
}
body {
  background-color: #fcfcfd !important;
  overflow: auto !important;
}
.stepper_form_section {
  padding: 160px 10px;
}
.form-item {
  display: flex;
  flex-direction: column;
}
.form-item.has-error .form-label {
  color: var(--danger_color);
}
.form-item.has-error .form-input {
  border-color: var(--danger_color);
  background-color: #ffe1e1;
}
.form-label {
  font-size: 16px;
  color: var(--text_color);
  margin-bottom: 6px;
}
.form-input,
select {
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  background: #ffffff;
  height: 50px;
  padding: 0 16px;
  outline: none;
  border-radius: 8px;
  margin-bottom: 10px;
}
select {
  max-width: 450px;
}

.form-content {
  display: none;
  flex-direction: column;
  gap: 20px;
}
.form-content.active {
  display: flex;
}

.form-buttons .btn {
  border-radius: 4px;
  border: 2px solid transparent;
  padding: 10px 24px;
  font-size: 16px;
  background-color: #fff;
  cursor: pointer;
  outline: none;
  transition: all ease-in-out 0.3s;
}
.form-buttons .btn.red {
  border-color: var(--primery_color);
  color: var(--primery_color);
}
.form-buttons .btn.primary {
  background-color: var(--primery_color);
  color: #fff;
}
.form-buttons .btn[disabled] {
  cursor: not-allowed;
}

.wizard {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 900px;
  margin: 0 auto 30px;
}
.wizard:before {
  content: "";
  position: absolute;
  background-color: #ebebeb;
  height: 5px;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.wizard-bar {
  position: absolute;
  background-color: var(--primery_color);
  height: 5px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: 0.3s ease;
}
.wizard-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
}
.wizard-item {
  z-index: 2;
  transition: 0.4s ease;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid var(--primery_color);
  color: var(--primery_color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background-color: #ffffff;
}
.wizard-item.active {
  background-color: var(--primery_color);
  color: #fff;
}

li.wizard-item span {
  position: absolute;
  top: 42px;
  color: var(--text_color);
  width: max-content;
  font-size: 18px;
  font-weight: 600;
}
li.wizard-item.active span {
  color: var(--primery_color);
}
.main_section_form {
  margin: 65px auto 30px;
  display: block;
  box-shadow: 0px 0px 10px #dddddda1;
  padding: 30px;
  border-radius: 20px;
  background: white;
  max-width: 1140px;
}
.main_section_form h1 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: var(--text_color);
}

.inputs_section {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.form-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: end;
  border-top: 1px solid #ddd;
  padding: 30px 0px 0px;
  margin-top: 30px;
}

.main_section_form span.text-underline {
  text-decoration: underline;
  text-underline-offset: 8px;
}

.main_section_form p {
  font-size: 18px;
  color: var(--text_color);
  line-height: 28px;
}

.main_section_form ul li {
  list-style: unset;
  line-height: 26px;
  margin-bottom: 5px;
  font-size: 16px;
}
.content_section ul li {
  list-style: none !important;
}
.content_section ul {
  padding: 0;
}
.content_section ul p input {
  margin: 5px 10px 0px 0px;
}

.forth_section ul li p {
  display: flex;
  align-items: self-start;
}

/* navbar start */
.top_marggin {
  margin-top: 100px;
}

body.fixed nav.square_nav_bar {
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
}
nav.square_nav_bar {
  min-height: 72px;
  height: auto;
  padding: 0px 20px;
  background-image: linear-gradient(#ffffffa1, #ffffffb0);
  max-width: 1597px;
  margin: 30px auto 0px;
  border-radius: 15px;
  border: 1px solid #ffffffbd;
  box-shadow: 0px 7px 23px #00000013;
  backdrop-filter: blur(5px);
  z-index: 1;
}

div#navbarTogglerDemo01 {
  max-width: 1600px;
  margin: 0 auto;
}
ul.navbar-nav.manu {
  justify-content: start;
  margin-top: 0px;
}

a.navbar-brand img {
  width: 200px;
}
div#navbarTogglerDemo01 a.nav-link {
  color: var(--sub_text_color);
  padding-right: 30px;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

a.nav-link.active {
  color: var(--primery_color) !important;
  text-decoration: underline !important;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px !important;
}

div#navbarTogglerDemo01 a.nav-link:hover {
  color: var(--primery_color);
  /* transform: scale(1.03); */
}
.filter_div a {
  text-decoration: none;
}

button.talk {
  border-radius: 50px !important;
  margin-left: auto !important;
  display: block !important;
  min-width: 150px;
}
div#navbarTogglerDemo01 a {
  text-decoration: none;
}
a.navbar-brand {
  min-width: 260px;
  margin: 0;
}
.react-responsive-modal-overlay.customOverlay {
  backdrop-filter: blur(0px) !important;
}
.react-responsive-modal-overlay.resend_modal_customOverlay {
  backdrop-filter: blur(2px);
}
/* navbar_end */

/* signature */

#signature-pad {
  border: 1px solid #ddd;
  width: 100%;
  height: 120px;
  cursor: crosshair;
  border-radius: 5px;
  margin-bottom: 10px;
}

#clear-button {
  cursor: pointer;
  color: white;
  text-decoration: none;
  color: var(--primery_color);
  border: none;
  padding: 5px 8px;
  font-size: 16px;
  border-radius: 4px;
}

.input_row {
  display: flex;
  flex-direction: column;
}

.inputs_row_section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 850px;
  margin: 20px 0px;
  gap: 20px;
}
.my-profile_section .minus-margin {
  margin-bottom: 20px !important;
}
.inputs_row_section input {
  border: none;
  border-bottom: 2px solid var(--td_color);
  border-radius: 5px;
  margin-bottom: 5px;
  height: 45px;
}
.inputs_row_section label {
  font-size: 18px;
  display: inline-block;
  font-weight: 600;
  color: var(--td_color);
}
.sig_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* new Style */

table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:before,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  right: 0 !important;
}

.table > :not(caption) > * > * {
  padding: 12px 24px !important;
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
}

.Store_section input {
  width: 100%;
  text-align: left;
  padding: 10px;
  border: 1px solid #9dbd4c36;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
}

td:last-child {
  max-width: 100px;
}

div#example_info {
  display: none;
}

.title h2 {
  color: var(--text_color);
  font-size: 20px;
  font-weight: 600 !important;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 65px;
  line-height: 28px;
}

.filter_div input {
  width: 100%;
  box-shadow: none !important;
  font-size: 15px;
  font-weight: 400;
  color: var(--text_color) !important;
  height: 40px;
}

select.form-select {
  max-width: 220px;
  border: 1px solid var(--primery_color);
  background-color: var(--primery_color);
  color: var(--primery_color);
  height: 34px;
}

.filter_div input::placeholder {
  color: var(--td_color) !important;
}

/*  -----------side bar---------- */

img.paypal-logo {
  width: 60px;
}

.nav_links {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.side_bar {
  background: #ffffff;
  max-width: 100px;
  border-radius: 0px;
  padding: 30px 14px;
  margin-top: 0;
  box-shadow: 2px 2px 0px #e7e7e7;
  height: 100vh;
}

.nav_links img {
  width: 35px;
}

.nav_links a {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 14px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  color: #9aa3b9;
  text-transform: capitalize;
}

.nav_links a.active {
  color: #071437;
}

.nav_links img {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 55px;
  height: 55px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 22px;
  padding: 12px;
}

.nav_links img {
  filter: brightness(0) saturate(100%) invert(64%) sepia(16%) saturate(329%)
    hue-rotate(186deg) brightness(98%) contrast(92%);
}

.nav_links a:hover img {
  filter: brightness(0) saturate(100%) invert(19%) sepia(17%) saturate(5722%)
    hue-rotate(208deg) brightness(96%) contrast(92%) !important;
  /* filter: brightness(0) saturate(100%) invert(67%) sepia(69%) saturate(3658%) hue-rotate(184deg) brightness(98%) contrast(107%); */
}

.nav_links a.active img {
  filter: brightness(0) saturate(100%) invert(19%) sepia(17%) saturate(5722%)
    hue-rotate(208deg) brightness(96%) contrast(92%) !important;
}

.nav_links div {
  border-radius: 22px;
  background: #f5f8facc;
  border-radius: 22px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  padding: 15px;
  border-bottom: 1px solid var(--border-color);
  gap: 20px;
}

.title input[type="search"]::placeholder {
  color: var(--sub_text_color) !important;
  font-size: 14px;
}

.refill-balldiv1 {
  display: flex;
  align-items: center;
  gap: 5px;
}
.refill-balldiv1 p {
  margin-bottom: 0;
}
.refill-balldiv img {
  margin-left: 5px;
}

.filter_div {
  display: flex;
  gap: 10px;
  width: 350px;
}
div#example_length {
  position: absolute;
  top: 85px;
  display: none;
}
/* store  */

.Store_section {
  margin: 0px auto 120px;
  position: relative;
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 14px;
  box-shadow: 0px 2px 10px #dddddd59;
}

.testReult {
  min-width: 130px;
}

div#example_wrapper .row .col-sm-12.col-md-6:nth-child(2) {
  display: none;
}
.container-fluid .my_team_section {
  padding: 0px;
}
.store_table div#example_info {
  margin: 0;
  padding: 20px;
}

.store_table div#example_paginate {
  margin: 0;
  padding: 10px !important;
  /* margin-left: 95px; */
}

.store_table .active > .page-link,
.page-link.active {
  background-color: #92b53a1a !important;
  border-color: var(--primery_color) !important;
  color: var(--primery_color) !important;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: "Inter", sans-serif !important;
}
a.page-link {
  min-width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: "Inter", sans-serif !important;
  border: 1px solid var(--border-color);
  color: var(--sub_text_color);
}
li#example_next a {
  border: none !important;
}
.container-fluid.dashboard_main_section a {
  text-decoration: none;
}
.store_table td {
  font-size: 14px;
  font-weight: 500;
  color: var(--td_color) !important;
  padding: 15px 20px !important;
}

.store_table th {
  font-size: 14px;
  font-weight: 600;
  color: var(--sub_text_color) !important;
}

td span.material-symbols-outlined {
  color: #263b80 !important;
  cursor: pointer;
}

span#back_arrow {
  padding: 8px;
  background: #9aa3b9;
  color: #f7f9fb;
  border-radius: 6px;
  cursor: pointer;
}

td {
  color: #263b80 !important;
}

/* profile for store css */

.dropdown {
  position: relative;
  display: inline-block;

  margin-left: auto;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 185px;
  overflow: auto;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-radius: 5px;
  right: -35px;
  top: 60px;
  padding: 16px 20px;
  width: 300px;
  border-radius: 20px;
  border: 1px solid #92b53a4a;
}

.user-login img {
  width: 30px;
  border-radius: 50px;
  padding: 5px;
  object-fit: scale-down;
}

button.user-login.dropbtn span {
  font-size: 18px;
}

.dropdown-content a {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: start;
  font-size: 16px;
  font-weight: 400;
  color: var(--text_color) !important;
  padding: 5px 0px;
  text-decoration: none;
}

.dropdown-content a img {
  width: 17px;
  filter: brightness(0) saturate(100%) invert(59%) sepia(36%) saturate(759%)
    hue-rotate(101deg) brightness(92%) contrast(87%);
}

.dropbtn {
  background-color: transparent !important;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: var(--text_color);
}

.user-login {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 400;
  letter-spacing: 0.2px;
  font-size: 16px;
  color: var(--text_color);
}

.admin_user {
  position: absolute;
  right: 0;
  top: 20px;
}

/* change password */

.change_password_card {
  padding: 0px !important;
  background: white;
  width: 100%;
  margin: auto;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #eaecf0;
  max-width: 1164px !important;
}
.change_password_card-settings {
  padding: 0px !important;
  background: white;
  width: 100%;
  margin: auto;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #eaecf0;
  max-width: 630px !important;
}

.change_password_card-settings form.settings-form .form-group.mb-4 {
  margin-bottom: 20px !important;
}
.change_password_card-settings .minus-margin label {
  width: 100%;
  margin-bottom: 10px;
}

.change_password_card .login-input-bx.minus-margin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.settings-form input {
  width: 100%;
  height: 52px;
  border-radius: 10px;
  border: 1px solid rgba(234, 236, 240, 1);
  padding: 20px;
  /* color: rgba(102, 112, 133, 1); */
  color: #1c1c1c;
  font-size: 14px;
  font-weight: 400px;
}
label.get-link-lable {
  display: flex;
  gap: 5px;
}
.get-link-lable a {
  font-size: 14px;
  font-weight: 600px;
  line-height: 21px;
  margin-bottom: 0;
  color: #009ef7;
  text-decoration: none;
}
.settings-form label {
  font-size: 16px !important;
  font-weight: 600px !important;
  line-height: 21px !important;
}
.setting-header {
  background-image: linear-gradient(
    90deg,
    rgba(119, 151, 37, 1) 0%,
    rgba(146, 181, 58, 1) 100%
  );
}
.setting-header .back_arrow {
  background-color: #ffffff;
}
.setting-header .back_arrow path {
  fill: rgba(146, 181, 58, 1);
}
.setting-row button {
  margin-left: 10px !important;
}
.TestResultsAlert {
  height: 60px;
  border-radius: 8px !important;
  border: 1px solid rgba(146, 181, 58, 1);
  background: rgba(142, 174, 61, 0.15);
  display: flex;
  align-items: center;
  padding: 0 16px;
  display: flex;
  cursor: pointer;
}
.test-resultbottom {
  margin-bottom: 30px;
  max-width: 1597px;
}
.TestResultsAlert span {
  color: rgba(146, 181, 58, 1);
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  margin-left: 8px;
}

.TestResultsAlert img {
  height: 21px;
  width: 21px;
  margin: 0 8px;
  fill: rgba(146, 181, 58, 1);
}
/* .info-icon {
  margin-right: 8px; 
} */

.info-icon, .message_bar_top .close-icon {
  width: 22px !important; /* Set fixed width */
  height: 22px !important; /* Set fixed height */
  min-width: 22px; /* Ensures it does not shrink */
  min-height: 22px;
}

.cross-icon {
  margin-left: auto !important; /* Push the cross icon to the right */
  height: 10px;
  width: 10px;
  fill: rgba(146, 181, 58, 1);
}

.change_password_card .login-input-bx.minus-margin span {
  width: 70%;
}
.change_password_card .subbtn {
  text-align: start;
  margin-top: 30px;
  margin-left: 23%;
}
.login-input-bx svg {
  position: absolute;
  margin: 14px 10px;
}

.magageheight {
  min-height: calc(100vh - 255px) !important;
}

.head_title h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.head_title {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border-bottom: 1px solid #eaecf0;
}
.Change_Password_section form {
  padding: 30px;
}
form.settings-form button.primery_btn {
  margin: 20px 0px 0px 0px !important;
}

.Change_Password_section .minus-margin {
  margin-bottom: 0;
}
/* login page */

.back_arrow {
  background: var(--primery_color);
  width: 28px;
  height: 28px;
  text-align: center;
  border-radius: 5px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.deaH {
  margin-top: unset !important;
}

input::placeholder {
  color: #667085 !important;
}
input[type="date"] {
  color: #667085;
  width: 100%;
  text-align: left;
  padding: 10px 10px;
  height: 52px;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  border: 1px solid rgb(236, 236, 236);
}
input.valid {
  position: relative;
  width: 100%;
  text-align: left;
  padding: 10px 10px;
  height: 52px;
  font-size: 14px;
  /* font-weight: 600; */
  /* border-left: 2px solid #8eae3d !important; */
  border: none;
  /* background-color: #ffffff; */
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  /* background: #8eae3d1a; */
  border: 1px solid rgb(236, 236, 236);
  color: #1c1c1c !important;
}

/* .login-input-bx {
  margin-bottom: 15px;
} */
.subbtn {
  text-align: center;
  margin-top: 30px;
}

.login-input-bx label {
  text-align: left;
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  color: var(--text_color) 222;
}

.login-heading-image {
  margin: 0px auto 40px;
  text-align: center;
}

.login_card {
  padding: 0px !important;
  background: white;
  box-shadow: 0px 0px 10px #ebebeb4d;
  max-width: 1360px !important;
  margin: auto;
  border-radius: 8px;
  /* overflow: hidden; */
  height: 768px;
}
.back_arrow_forgot p {
  margin-top: 2px;
  margin-left: 9px;
}

.login-heading-image h2 {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin: 10px;
}

.login_form {
  margin: 80px auto;
  border-radius: 10px !important;
  padding: 0px;
}

.login-heading-image img {
  max-width: 133px;
}
input.valid::placeholder {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
}
.row.dt-row {
  overflow: auto;
}
div#example_wrapper {
  overflow: hidden;
}
.login_section {
  padding: 80px 60px;
  background-color: white;
}

.graphic_section {
  /* background-image:  url(../../assets/images/Rectangle\4png); */
  background-image: url(../../assets/images/Rectangle\ 4.jpg);
  height: 100%;
  width: 100%;
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
/* .filter_Prescription{
  width: 290px;
} */

.rememberr label {
  color: #1c1c1c;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.rememberr p {
  margin: 0;
}
.rememberr a {
  text-decoration: none;
  color: var(--primery_color);
}

.rememberr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rememberr input[type="checkbox"] {
  width: 15px;
  height: 15px;
}
.graphic_section .GetHairMD {
  max-width: 344px;
  background: #ffffff1c;
  padding: 39px 30px 127px 30px;
  border-radius: 30px;
  border: 1px solid #ffffff6b;
}

img.bestseller {
  position: absolute;
  top: 107px;
  right: -35px;
  padding: 16px;
  background: white;
  border-radius: 78px;
}
.graphic_section div {
  position: relative;
}
table.dataTable.table-striped > tbody > tr:nth-of-type(2n + 1) > * {
  box-shadow: inset 0 0 0 9999px rgba(255, 255, 255, 0) !important;
}

th {
  background: #fcfcfd !important;
}
.store_table table.dataTable {
  margin: 0 !important;
}

.img_div img {
  max-width: 24px;
  cursor: pointer;
}
.img_div {
  display: flex;
  align-items: center;
  gap: 14px;
}

/* detail page css */

.detail_head img {
  max-width: 85px;
}
.container-fluid.detail_main_section {
  padding-top: 160px;
}
.detail_head {
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
}
button.user-login.dropbtn:hover {
  box-shadow: none !important;
}

.primery_btn:hover {
  box-shadow: 0px 0px 5px #ddd;
  transform: translatey(-2px);
}

.btn_section img {
  height: 40px;
  width: 40px;
  padding: 0;
}

button.download {
  background: white;
  border: 2px solid var(--primery_color);
  font-size: 16px;
  color: var(--primery_color);
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
}
.btn_section {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 235px;
  justify-content: end;
}
button.download img {
  height: 20px;
  width: 20px;
}
.btn_section button {
  border-radius: 8px !important;
}

.btn_section button:hover {
  background: var(--primery_color);
  color: white;
}
.btn_section button:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
    hue-rotate(93deg) brightness(103%) contrast(103%);
}
.detail_content {
  padding: 30px 20px;
  background: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 10px #dddddd3d;
  border-radius: 20px;
  border: 1px solid #dddddd78;
  margin-top: 20px;
  gap: 20px;
}
.info_row {
  padding: 14px;
  border-left: 3px solid #0c2aba12;
  margin-bottom: 30px;
  background: #f3f5ff12;
}

.info_row label {
  margin-bottom: 5px;
}
.info_row h3 {
  font-size: 22px;
  font-weight: 600;
}

.profile_img img {
  width: 100%;
  padding: 0;
  margin: 0 auto;
  display: block;
  max-width: 265px;
  border-radius: 162px;
  max-height: 265px;
  object-fit: cover;
}
.form-buttons {
  text-align: end;
  display: block;
}

.profile_img {
  overflow: hidden;
  border-radius: 100%;
  background: #cfcfd038;
  border: 4px solid #0c2aba29;
}
.profile_img:hover img {
  transform: scale(1.05);
}
.inner_profile_section {
  padding: 30px 20px;
  background: white;
  box-shadow: 0px 0px 10px #dddddd3d;
  border-radius: 20px;
  border: 1px solid #dddddd78;
  margin-top: 20px;
}
button.approve {
  margin-left: auto;
  display: block;
  margin-top: 30px;
}
/* end details css */

/* dashboard css */

.container-box {
  max-width: 1600px;
  margin: 0 auto;
}

.dashborad_card h3 {
  font-size: 20px;
  line-height: 26px;
  color: var(--text_color);
  font-weight: 600;
}

.inner_card_content {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.inner_card_content img {
  max-width: 92px;
  transition: all ease-in-out 0.2s;
}

.inner_card_content h2 {
  font-size: 42px;
  font-weight: 600;
  margin-top: 60px;
  font-family: sans-serif;
  margin-bottom: 0px;
}

.dashborad_card:hover {
  box-shadow: 10px 20px 30px #b9c2eb3d;
}

.dashborad_card:hover img {
  transform: scale(1.05);
}

.dashborad_card {
  background: white;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #9dbd4c38;
  box-shadow: 0px 5px 10px #b9c2eb40;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  margin-bottom: 30px;
}

/* end dashboard css */

/* Completed Test */

.upload img {
  width: 20px;
}

.upload label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.upload input {
  display: none;
}

.download img {
  width: 20px;
}

.download label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 15px;
  color: var(--td_color);
}

.download a {
  text-decoration: none;
}
/* Completed Test end */

/* dashboard */

.dashboard_cards {
  background: white;
  border: 1px solid #9dbd4c59;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  width: 100%;
  margin-bottom: 25px;
  transition: all ease-in-out 0.3s;
}
.dashboard_cards img {
  position: absolute;
  right: 20px;
  top: 40px;
  height: 92px;
  width: 95px;
}
.dashboard_cards h4 {
  margin-bottom: 45px;
  font-size: 20px;
  font-weight: 600;
  color: var(--text_color);
}

.dashboard_cards h3 {
  font-size: 42px;
  margin: 0;
  color: var(--text_color);
}
.dashboard_cards:hover {
  box-shadow: 10px 10px 10px #b9c2eb33;
}

/* footer section */

.container-fluid.footer_section {
  background: #222;
  padding: 20px;
  color: white;
}

.inner_colum_footer p {
  display: flex;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  justify-content: center;
  margin: 0;
}

/* .breadcrumb  */

.breadcrumb {
  margin: 0 !important;
  height: 80px;
}

.breadcrumb p {
  text-align: end;
  display: block;
  margin-left: auto;
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 14px;
  margin-bottom: 0px;
}
.UploadTestResults {
  border-radius: 60px !important;
}

.breadcrumb b {
  font-weight: 600 !important;
}

.breadcrumb a {
  /* color: var(--td_color); */
  text-decoration: none;
  font-size: 14px;
}
.breadcrumb p {
  color: rgba(102, 112, 133, 1) !important;
}
.result-view {
  display: contents;
}

/* table css */

.title h2 svg {
  background: var(--primery_color);
  height: 28px;
  width: 28px;
  border-radius: 5px;
  padding: 8px;
}

p.Ordered {
  margin: 0;
  padding: 4px 10px;
  /* background: #8eae3d36; */
  background-color: #f3f8eb;
  display: inline;
  border-radius: 20px;
  color: var(--primery_color);
  font-size: 12px;
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
  /* display: contents; */
}
.Submitted {
  background: rgba(217, 169, 0, 0.15) !important;
  color: rgba(203, 158, 0, 1) !important;
  /* rgba(203, 158, 0, 1) !important; */
}
.vector {
  height: 12px !important;
}
p.Ordered svg {
  margin-top: -2px;
}
.viewcolor {
  color: rgba(102, 112, 133, 1) !important;
}
.personal-aspects-list {
  font-weight: 400px;
  font-size: 16px;
  line-height: 32px;
  color: rgba(28, 28, 28, 1);
  padding: 20px !important;
  margin: 0;
}

.row.setting-row.personal_section {
  padding: 0px 20px;
}
.personal-aspects-list a {
  color: rgba(146, 181, 58, 1);
  text-decoration: none;
}

p.Ordered.Modified {
  background: #cb9e002e;
  color: #cb9e00;
}
p.refill_less {
  margin: 0;
  padding: 4px 10px;
  background: #ae3d3d36;
  display: inline;
  border-radius: 20px;
  color: var(--danger_color);
  font-size: 12px;
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
}

p.Ordered.Invited {
  background: #f2f4f7;
  color: var(--sub_text_color);
}

.my_team_section th.sorting.sorting_asc {
  max-width: 50px;
}

/* pagination */
ul.pagination {
  gap: 16px;
}

li#example_previous a {
  background: transparent !important;
  border: none;
  font-size: 16px;
  color: var(--sub_text_color);
}

li#example_next a {
  background: transparent !important;
}

/*  */

.action img {
  width: 14px;
}
.action {
  display: flex;
  align-items: center;
  gap: 5px;
}

.test_results {
  display: flex;
  align-items: center;
  gap: 20px;
}

button.primery_btn.invite {
  min-width: auto;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

/* modal invite members */

.inner_section {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-top: 20vh;
}
.invite_card {
  max-width: 872px;
  margin: auto;
  background: white;
}
.invite_modal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #1a240091;
  backdrop-filter: blur(4px);
  z-index: 9;
}
.inner_section img {
  max-width: 300px;
  padding: 50px;
  background: #8eae3d17;
}

.invite_content {
  max-width: 428px;
  text-align: center;
}
.invite_content h2 {
  font-weight: 600;
  font-size: 40px;
}

.login_card .row {
  height: 100%;
}
.profile-right {
  display: flex;
}

.header-manage {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.user-modal-section {
  position: absolute !important;
  right: 118px !important;
  top: 105px;
  border-radius: 20px;
  padding: 16px 20px 16px 20px !important;
  height: 125px !important;
  width: 293px !important;
  border: 1px solid #9dbd4c59;
  margin: 0px !important;
  background: rgba(255, 255, 255, 1) !important;
}
.orders-modal-section {
  position: absolute !important;
  top: 105px !important;
  border-radius: 20px;
  padding: 10px 0px 10px 10px !important;
  height: 87px !important;
  width: 196px !important;
  border: 1px solid #9dbd4c59;
  margin: 0px !important;
  background: rgba(255, 255, 255, 1) !important;
  left: 648px;
}

.customOverlay {
  background: none !important;
}
.resend_modal_customOverlay {
  background: rgba(26, 36, 0, 0.6) !important;
}
.orders-modal-section .react-responsive-modal-closeButton {
  display: none !important;
}
.user-modal-section .react-responsive-modal-closeButton {
  display: none !important;
}
.resend_modal {
  height: 356px;
  max-width: 872px;
  top: 296px;
  /* left: 524px; */
  border-radius: 8px;
  background: rgba(255, 255, 255, 1) !important;
}

div#navbarTogglerDemo01 p {
  color: var(--sub_text_color);
  padding-right: 30px;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.resend {
  cursor: pointer;
}
.profile-content p {
  margin: 3px;
  cursor: pointer;
  color: rgba(28, 28, 28, 1);
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
.ordermodal p {
  margin-bottom: 7px !important;
}
.ordermodal img {
  width: 21px;
  height: 18px;
}

.user-profile p {
  font-size: 13px;
  color: #92b53a;
}
.user-profile {
  cursor: pointer;
  position: relative;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}
.user-profile h6 {
  margin: 0px;
  /* font-size: 12px; */
}
.user-profile .user-name {
  font-size: 12px !important;
  margin-bottom: 1px;
  color: #92b53a !important;
}
.user-profile .user-pro {
  font-size: 14px !important;
  margin-bottom: 0;
}
.user-profile-img img {
  width: 12px;
  height: 11px;
}
.user-profile-img1 img {
  transform: rotate(180deg);
}

.profile-content a {
  text-decoration: none;
  color: #1c1c1c;
}
input.priceperkit {
  height: 45px !important;
  width: 70% !important;
}
.setting-row {
  align-items: center;
  font-weight: 600;
  width: 100%;
}
.head_footer {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border-top: 1px solid #eaecf0;
  justify-content: end;
}
/* .form-check-input:checked {
  background-color: #92b53a;
  border-color: #92b53a;
  width: 32px;
  padding: 10px 6px 9px 29px;
  margin: 1px;
} */
.page-link {
  background: transparent !important;
}
li#example_next button {
  border: none !important;
  cursor: pointer;
  color: #667085;
}

button.page-link {
  min-width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: "Inter", sans-serif !important;
  border: 1px solid var(--border-color);
  color: rgba(102, 112, 133, 1);
}
p.resend {
  background-color: rgba(217, 169, 0, 0.15) !important;
  color: #d9a900;
}
p.delete {
  background-color: rgba(255, 0, 0, 0.1) !important;
  color: rgba(255, 0, 0, 1);
}
li#example_previous button {
  border: none !important;
  cursor: pointer;
}
/* div#navbarTogglerDemo01 a.nav-link {
  text-decoration: none !important;
} */
ul.pagination {
  justify-content: center;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-bg-type: "none";
}
.active p {
  color: rgba(146, 181, 58, 1);
}
.active-new-class a {
  color: rgba(
    146,
    181,
    58,
    1
  ) !important; /* Change this to the color you want for active links */
}
.active-new-class-order a {
  color: rgba(146, 181, 58, 1) !important;
}
.new-Orders {
  color: #667085 !important;
}
.new-Orders:hover {
  color: rgba(146, 181, 58, 1) !important;
}
th.set-width {
  width: 19%;
}

.viewimage,
.viewimage img {
  height: 15px;
  width: 15px;
  background-color: transparent !important;
}
p.Ordered.viewimage.provider-view {
  color: unset;
}
.viewimage span {
  font-size: 12px;
  font-weight: 500;
  margin-left: 3px;
}

/* .resend_modal {
  display: flex;
  align-items: center;
  justify-content: center;
} */
.Ordered {
  cursor: pointer;
}
.modal-content {
  position: relative;
  left: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.modal-content p {
  font-weight: 600;
  font-size: 25px;
  line-height: 38.4px;
  text-align: Center;
}
.bgsend-icon {
  width: 146px;
  height: 156px;
}
.status span {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}
.status .nodelivery {
  color: rgba(102, 112, 133, 1);
}
.OrderTraking {
  margin: 0px !important;
  width: 100%;

}

.button-sure .no {
  width: 65px;
  height: 40px;
  border-radius: 8px;
  padding: 10px, 16px, 10px, 16px;
  border: 1px solid rgba(146, 181, 58, 1);
  background-color: white;
  color: rgba(146, 181, 58, 1);
}
.button-gap {
  margin-right: 10px;
}
.button-sure .yes {
  width: 65px;
  height: 40px;
  border-radius: 8px;
  padding: 10px, 16px, 10px, 16px;
  border: 1px solid rgba(146, 181, 58, 1);
  background-color: rgba(146, 181, 58, 1);
  color: rgba(255, 255, 255, 1);
}
/* .details_heading {
  font-weight: 700;
  font-size: 16px;
  color: rgba(146, 181, 58, 1);
  margin-bottom: 3px;
  line-height: 21px;
} */
.details_heading h5 {
  font-weight: 700;
  font-size: 16px;
  color: rgba(146, 181, 58, 1);
  /* margin-bottom: 3px; */
  line-height: 21px;
}
.details_heading h2 {
  font-weight: 600;
  font-size: 17px;
  color: rgba(28, 28, 28, 1);
  line-height: 20px;
}
.details_value {
  font-weight: 700;
  font-size: 18px;
  color: rgba(28, 28, 28, 1);
  line-height: 20px;
}

/* TestResults.css */

/* .test-results-container {
  height: 100%;
  overflow-y: auto !important;
} */
.tracking-item .date {
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: rgba(120, 120, 120, 1);
}

.scroll-popup {
  max-height: 680px;
  /* overflow: auto; */
}
.test-results-container {
  overflow-y: scroll;
  height: 800px;
}
.test-results-container::-webkit-scrollbar-thumb {
  background: #92b53a;
  border-radius: 4px;
}
input[type="date"] {
  color: #667085;
}

.react-responsive-modal-modal.TestResultsAvailableModal::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Handle */
.react-responsive-modal-modal.TestResultsAvailableModal::-webkit-scrollbar-thumb {
  background: #92b53a;
  border-radius: 4px;
}

.Ghmduser {
  color: black !important;
  font-size: 14px !important;
  line-height: 19px !important;
  font-weight: 400;
}
.row.details_heading {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 10px;
    row-gap: 20px;
}
.test-result-card {
  /* border: 1px solid #92B53A; */
  border-radius: 8px;
  margin-bottom: 20px;
  /* padding-bottom: 20px; */
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #92b53a;
  padding: 20px 30px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 76px;
}

.patient-name {
  font-weight: bold;
  color: white;
  font-size: 20px;
  font-weight: 600;
}
.details-button a {
  text-decoration: none;
  color: rgba(146, 181, 58, 1);
  font-size: 14px;
  font-weight: 600;
}

.view-details-button {
  background-color: rgba(255, 255, 255, 1);
  color: #92b53a;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
  width: 122px;
}

.view-details-button:hover {
  background-color: #f0f0f0;
}
.details-button {
  background-color: white;
  color: #92b53a;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 10px;
}
.close-icon {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.alert-note {
  background-color: rgba(255, 196, 196, 1);
  color: rgba(255, 0, 0, 1);
  padding: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
}

.test-info {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.info-column {
  flex: 1;
  padding: 0 10px;
}

.prescription {
  background-color: #f5f5f5;
  border-radius: 10px;
  max-width: 1597px;
  min-height: 253px;
  border: 1px solid rgba(234, 236, 240, 1);
  padding: 0;
  margin: 0 auto !important;
}
.viewDetailsPre,
.PrescriptionInnerCompo {
  margin: 0 auto !important;
  /* margin: 0px !important; */
  margin-bottom: 20px !important;
  width: 100% !important;
  max-width: 1597px;
}
.PrescriptionInnerCompo .Prescr-textarea textarea {
  border: 1px solid rgba(234, 236, 240, 1);
  background-color: unset;
}
.pre-redfillsbtndiv.PrescriptionInnerCompo1 {
  margin: unset !important;
}
.modify-div {
  display: flex;
  justify-content: end;
  margin-right: 30px;
  gap: 10px;
}
.refilesnew select.form-select option {
  color: #667085;
}
.modify-div .form-select {
  background-color: #fff;
  border: 1px solid var(--primery_color);
  border-radius: 10px;
  color: var(--primery_color);
  height: 43px;
  max-width: 240px;
}
.modify-maindiv {
  display: flex;
  justify-content: end;
  height: 43px;
  margin-left: 30px;
  gap: 10px;
}
.modify-maindiv button.modify-btn {
  background: #f66;
  border: 1px solid #f66;
}
.modify-div .approve-btn {
  border-radius: 8px;
  padding: 12px 20px;
  background-color: transparent;
  color: var(--primery_color);
  border: 1px solid var(--primery_color);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Prescr-textarea textarea {
  border: 1px dotted var(--text_colorfull);
  border-radius: 10px;
  margin: 25px 30px 25px 30px;
  padding: 20px 25px 25px 25px;
  width: 100%;
  max-width: 96%;
  height: 118px;
  background-color: #f5f7f1;
}
.PendingReviewsection-div .test-result-card {
  margin: 0px 0px 0px 0px;
}
.PendingReviewsection-div .pre-redfillsbtndiv {
  margin: 0px 30px 10px 30px;
}
.PendingReviewsection-div {
  margin-bottom: 50px;
}
.PendingReviewsection-div .Prescr-btndiv {
  margin-bottom: 30px;
  margin-right: 0px;
}
.modify-btn {
  border-radius: 8px;
  padding: 12px 10px;
  background-color: var(--primery_color);
  border: 1px solid var(--primery_color);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: var(--text_white);
  display: flex;
  justify-content: center;
  align-items: center;
}
.Pharmacy-pausediv {
  display: flex;
  gap: 10px;
}
.Pharmacy-pausediv .pause {
  border-radius: 8px;
  padding: 10px 14px;
  background-color: var(--primery_color);
  border: 1px solid var(--primery_color);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: var(--text_white);
  display: flex;
  justify-content: center;
  align-items: center;
}
.Pharmacy-pausediv .cancel {
  border-radius: 8px;
  padding: 10px 14px;
  background-color: #df3744;
  border: 1px solid #df3744;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: var(--text_white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewDetailsPre .preseinner p {
  color: white !important;
}
/* .PrescriptionInnerCompo{

} */
.viewDetailsPre .test-result-card .card-header {
  background-color: rgba(146, 181, 58, 1) !important;
}
.prescription-test {
  background-color: #fff !important;
}
.prescriptioninner-test {
  background-color: #fff !important;
}

.prescriptioninner {
  background-color: #f5f5f5;
  border-radius: 10px;
  max-width: 100%;
  min-height: 118px;
  border: 1px solid rgba(234, 236, 240, 1);
  margin: 30px 30px;
  padding: 24px;
}
.prescriptioninner.pre-main {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.pre-right {
  min-width: 230px;
  text-align: right;
}
.phar-change {
  text-align: unset;
}
.pre-left-prescription {
  width: -webkit-fill-available;
}
/* .pre-left-prescription textarea{
  background: rgb(241 242 233);
} */
.prescriptioninner.Minoxidil.pre-main textarea {
  background: rgb(241 242 233);
}
.pre-left .refile-p {
  font-size: 18px;
  color: rgba(102, 112, 133, 1);
  line-height: 20px;
  font-weight: 600;
  line-height: 24.55px;
}
.prescriptioninner p {
  font-size: 16px;
  color: rgba(102, 112, 133, 1);
  line-height: 20px;
  margin-bottom: 8px;
}
.prescriptioninner p:last-child {
  margin-bottom: 0px;
}
.prescription.PrescriptionInnerCompo {
  background: white;
}
.prescription.PrescriptionInnerCompo .prescriptioninner {
  background: white;
}
.Minoxidil {
  background-color: rgba(142, 174, 61, 0.06);
  border: 1px dotted rgba(146, 181, 58, 1);
  max-width: 100%;
}
.card-header.preseinner {
  border-radius: 10px 10px 0px 0px;
  background-color: rgba(234, 236, 240, 1) !important;
  height: 60px;
}
.preseinner p {
  font-weight: 600;
  font-size: 20px;
  margin: 0;
}
p.status-title {
  font-size: 17px !important;
}
.copy-div {
  display: flex;
  justify-content: end;
  margin-right: 30px;
  margin-bottom: 20px;
}
.copy-div .copy-btn {
  background-color: transparent;
  border: 1px solid #92b53a;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 300;
  padding: 0px 6px;
  color: #92b53a;
}

.patients-pdfdiv .edit-btn {
  height: 40px;
  border: 1px solid var(--text_colorfull);
  background-color: var(--text_colorfull);
  border-radius: 8px;
  cursor: pointer;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 600;
  color: var(--text_white);
  padding-right: 20px;
}


.TestResultsAvailableModal {
  max-width: 1597px !important;
  width: 100% !important;
  height: auto;
  border-radius: 8px 8px 0 0;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #92b53a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 20px, 30px, 20px, 30px; */
  background-color: rgba(255, 255, 255, 0.06);
  padding: 0px !important;
  margin: 0 !important;
}
.TestResultsAvailableModal .react-responsive-modal-closeButton {
  display: none !important;
}
.customOverlay_result {
  background: rgba(26, 36, 0, 0.6) !important;
}

.details-close-container {
  display: flex;
  align-items: center;
}
.react-responsive-modal-modal {
  margin: auto !important;
  width: 100%;
}

.Invite_Members-modal-section {
  max-width: 872px !important;
  width: 100% !important;
  /* height: 324px; */
  border-radius: 8px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #92b53a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
  padding: 0px !important;
}
.content-invite h2 {
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  text-align: center;
  margin-bottom: 10px;
}
.content-invite {
  width: 428px;
  background-color: #071437;
}
.content-invite p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: rgba(102, 112, 133, 1);
}

.InviteNewMembers {
  background: rgba(26, 36, 0, 0.6) !important;
}
.invite_profile-content .content-invite {
  /* height: 100%; */
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
}

.contetnof input {
  padding: 10px;
}
.image-invite {
  background-color: rgba(142, 174, 61, 0.06);
}
.invite_profile-content {
  display: flex;
}
.invite_profile-content .image-invite {
  width: 100%;
  height: 100%;
  max-width: 300px;
}
.invite_profile-content .content-invite {
  max-width: 560px; 
  padding: 0px 30px;
}
.invite_profile-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 408px;
}
.image-invite img {
  flex: 0 0 auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  padding: 12px 40px 0px 40px;
  width: 100%;
  object-fit: contain;
}

.primery_btn-invite {
  justify-content: center;
  display: flex;
}
input#your_unique_start_date_id {
  max-width: 86px;
}
input#your_unique_start_date_id:hover {
  cursor: pointer !important;
}
input#your_unique_end_date_id:hover {
  cursor: pointer !important;
}

.filter_div_ input {
  border: none !important;
  padding: 14px, 16px, 14px, 16px;
  /* width: 80px; */
  padding: 0px;
  font-weight: 400;
}
.DateInput__small {
  width: 90px !important;
}
.DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_2.DateRangePickerInput__showClearDates.DateRangePickerInput__showClearDates_3 {
  min-width: 250px !important;
  border: 1px solid rgba(208, 213, 221, 1) !important;
  border-radius: 8px !important;
}
.DateRangePickerInput__showClearDates {
  border: 1px solid rgba(208, 213, 221, 1) !important;
  border-radius: 6px !important;
  padding: 6px !important;
  width: 237px !important;
}

.right-side {
  display: flex;
  gap: 10px;
}
.filter_provider {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  border: 1px solid rgba(146, 181, 58, 1);
  height: 40px;
  color: rgba(146, 181, 58, 1);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  max-width: fit-content;
}
.downloadIcon img {
  height: 14px;
  width: 14px;
  margin-top: -3px;
}
.refillday {
  background-color: rgba(255, 0, 0, 0.2) !important;
  color: rgba(255, 0, 0, 1) !important;
}

.order-tracking-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 600px;
  padding: 20px;
}

.order-tracking-container .header {
  background-color: rgba(146, 181, 58, 1) !important;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.downloadIcon {
  width: 15px;
  height: 16px;
}

.tracking-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 28px;
  /* border-bottom: 1px solid #e0e0e0; */
}
.tracking-item1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23px 28px;
}

.tracking-item:last-child {
  border-bottom: none;
}

.tracking-item .status {
  display: flex;
  align-items: center;
}

.tracking-item .status svg {
  margin-right: 10px;
}

.tracking-item.date {
  color: #757575;
}

.primery_btn a {
  text-decoration: none;
  color: white;
}
.back_arrow_forgot {
  position: absolute;
  margin-top: -69px;
  margin-left: 10px;
  display: flex;
}

.chat_tab {
  padding: 30px 20px;
}
.chat.Provider {
  max-width: 450px;
}

span.Date {
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 112, 133, 1);
  margin: 2px;
}
.chat.user {
  text-align: end;
  display: block;
  margin-left: auto;
  max-width: 450px;
}
.chat p {
  background: rgba(102, 112, 133, 1);
  max-width: 650px;
  display: inline-block;
  color: white;
  padding: 10px;
  border-radius: 10px 10px 0px 10px;
  margin: 0;
}

.chat_box {
  max-width: 1165px;
  margin: auto;
}
span.Name {
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 112, 133, 1);
  display: block;
  margin: 2px;
}
.chat_info_provider {
  display: flex;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  max-width: fit-content;
}

.chat_info_user {
  display: inline-block;
}
.chat_info_user .chat-right {
  background-color: rgba(234, 236, 240, 1);
  color: rgba(28, 28, 28, 1);
}
.chat.user span.Date {
  display: block;
  text-align: start;
}

/* //// */
.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #cccccc00;
  border-radius: 5px;
  margin-right: 10px;
}
.chat-input input:focus-visible {
  outline: none;
}

button.details-button:focus-visible {
  outline: none;
}
.send-button {
  /* background-color: #4caf50; */
  border: none;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
}

.treacking_table {
  position: relative;
  z-index: 0000;
}
.treacking_table::before {
  z-index: 0;
  content: "";
  border: 1px dashed #667085;
  width: 1px;
  height: 50px;
  position: absolute;
  left: 40px;
  top: 33px;
}

.treacking_table::after {
  z-index: 0;
  content: "";
  border: 1px dashed #667085;
  width: 1px;
  height: 50px;
  position: absolute;
  left: 40px;
  top: 100px;
}

.treacking_table1 {
  position: relative;
  z-index: 0000;
}
.treacking_table1::before {
  z-index: 0;
  content: "";
  border: 1px dashed #8eb746;
  width: 1px;
  height: 50px;
  position: absolute;
  left: 40px;
  top: 33px;
}

.treacking_table1::after {
  z-index: 0;
  content: "";
  border: 1px dashed #667085;
  width: 1px;
  height: 50px;
  position: absolute;
  left: 40px;
  top: 100px;
}
.treacking_table2 {
  position: relative;
  z-index: 0000;
}
.treacking_table2::before {
  z-index: 0;
  content: "";
  border: 1px dashed #8eb746;
  width: 1px;
  height: 50px;
  position: absolute;
  left: 40px;
  top: 33px;
}

.treacking_table2::after {
  z-index: 0;
  content: "";
  border: 1px dashed #8eb746;
  width: 1px;
  height: 50px;
  position: absolute;
  left: 40px;
  top: 100px;
}
.tracking-item svg {
  z-index: 9;
}
.viewDetailsPre {
  background-color: rgba(142, 174, 61, 0.06);
}

.filter_div_ img {
  position: absolute;
  right: 10px;
  top: 8px;
}
button.DateRangePickerInput_clearDates.DateRangePickerInput_clearDates_1.DateRangePickerInput_clearDates__small.DateRangePickerInput_clearDates__small_2.DateRangePickerInput_clearDates_default.DateRangePickerInput_clearDates_default_3 {
  right: 25px;
}
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb00 !important;
  border-radius: 10px;
}

.footer p {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: white;
  margin: 2px;
}
.footer {
  width: 100%;
  background: rgba(28, 28, 28, 1);
  padding: 18px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 9;
}
.filter_div_ {
  position: relative;
}

.main_menu_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-user .primery_btn {
  width: 142px;
  height: 39px;
  border-radius: 60px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.ProviderDetails_card {
  padding: 0px !important;
  background: white;
  margin: auto;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #eaecf0;
  /* max-width: 1596px !important; */
  max-width: 1597px;
  width: 100%;
}
.pre-btndiv .pause {
  width: 85px;
  height: 39px;
  border-radius: 8px;
  background-color: #92b53a;
  border: 1px solid #92b53a;
  color: #ffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}
.pre-btndiv .cancel {
  width: 85px;
  height: 39px;
  border-radius: 8px;
  background-color: #df3744;
  border: 1px solid #df3744;
  color: #ffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}
.pre-btndiv {
  display: flex;
  gap: 10px;
  padding-bottom: 25px;
  justify-content: end;
}
.pre-redfillsbtndiv {
  display: flex;
  gap: 10px;
  justify-content: end;
  margin: 0px 30px;
}
.pre-redfillsbtndiv .edit {
  width: 80px;
  height: 39px;
  border-radius: 8px;
  border: 1px solid #92b53a;
  background-color: #f5f7f2;
  color: #92b53a;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}
.pre-redfillsbtndiv .delete {
  width: 69px;
  height: 39px;
  border-radius: 8px;
  background-color: #df3744;
  border: 1px solid #df3744;
  color: #ffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}
.pre-redfillsbtndiv .submit {
  width: 69px;
  height: 39px;
  border-radius: 8px;
  background-color: #92b53a;
  border: 1px solid #92b53a;
  color: #ffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}

.remember-forgiot {
  margin-top: 20px !important;
  max-width: 550px;
  margin: 0 auto;
}
.login-input-bx {
  max-width: 550px;
  margin: 0 auto;
  margin-bottom: 20px !important;
}
.download_icon {
  height: 15px;
  margin-right: 5px;
}
.downloadIcon,
.download-test {
  cursor: pointer;
}

.filterdata {
  position: absolute !important;
  z-index: 1;
  background: white;
  top: 45px;
  border: 1px solid #ddd;
  width: max-content;
  border-radius: 4px;
}
.drop_select {
  position: relative;
}

.border-none {
  border: none !important;
}
.center_section_password {
  /* margin-top: 80px; */
  /* min-height: calc(100vh - 237px) !important; */
}

.minus-margin label {
  width: 30%;
}
.minus-margin span {
  width: 100%;
}
.minus-margin {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  align-items: center;
}
.filter_provider {
  cursor: pointer;
}
.react-responsive-modal-modal.ProviderPopup-modal-section {
  left: 327px;
  top: 285px;
  width: 12%;
  border-radius: 5px;
  padding: 10px;
}

.react-responsive-modal-modal.Status-modal-section {
  left: 455px;
  top: 285px;
  width: 12%;
  border-radius: 5px;
  padding: 10px;
}
.ProviderPopup-modal-section .react-responsive-modal-closeButton {
  display: none;
}
.react-responsive-modal-modal.Status-modal-section
  .react-responsive-modal-closeButton {
  display: none;
}
.ShippingStatus {
  position: relative;
}
.provider-data p {
  padding: 4px;
  cursor: pointer;
  color: rgb(100 100 100);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  z-index: 99999;
}
.react-responsive-modal-modal.TestResultsAvailableModal {
  margin-top: 30px !important;
}

.filterdata2 {
  position: absolute !important;
  z-index: 1;
  background: white;
  top: 45px;
  border: 1px solid #ddd;
  width: max-content;
  border-radius: 4px;
  /* left: 1110px; */
  /* right: 345px; */
}
.Provider-container .dropdown-menu {
  display: unset;
  border: 1px solid var(--pop-border);
  left: 0;
  top: 50px;
}
.Provider-container li {
  padding: 6px 10px;
  width: 190px;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: var(--text_color);
}

.user-modal-popup {
  position: absolute;
  width: max-content;
  background: white;
  right: 0;
  border-radius: 20px;
  border: 1px solid rgba(146, 181, 58, 0.3);
  padding: 12px 18px;
  top: 75px;
  transition: all ease-in-out 0.3s;
  width: 250px;
  right: 0;
}
.user_profile {
  display: flex;
  gap: 30px;
}
.profile-content {
  display: flex;
  flex-direction: column;
  gap: 2px;
  z-index: 999999;
}

.signup_main .login_card {
  height: 1017px;
  max-width: 1360px;
}
.signup_main .LoginBanner {
  height: 1017px;
}
.signup-contant h2 {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  line-height: 38.19px;
  color: var(--text_color);
  margin-bottom: 5px;
}
.signup-contant p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  color: var(--sub_text_color);
  text-align: center;
  padding: 0 65px;
}
.sign-multidiv {
  display: flex;
  gap: 40px;
  margin-top: 10px;
}
.sign-multidiv .login-input-bx {
  flex: 1;
  width: 50%;
}

.option-span {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  color: var(--sub_text_color);
  text-align: center;
}
.signup_subbtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.signup_subbtn .primery_btn {
  width: 183.33px;
  height: 46px;
  border-radius: 8px;
  padding: 10px 16px 10px 16px;
  background-color: var(--primery_color);
  border: 1px solid var(--primery_color);
  font-size: 16px;
  font-weight: 600;
  line-height: 21.82px;
  color: var(--text_white);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.select-roll {
  display: flex;
  justify-content: center;
  margin-top: -68px;
  position: absolute;
  /* align-items: center; */
}

.addtraking {
  color: rgba(146, 181, 58, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
.traking_data {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
}
.traking_data button.primery_btn.invite {
  display: block !important;
  height: 52px;
  width: 101px;
}
.traking_data input {
  max-width: 500px;
  width: 100%;
  height: 52px;
  border-radius: 10px;
  /* border-color: rgba(255, 255, 255, 1); */
  border: 1px solid rgba(208, 213, 221, 1);
  padding: 10px;
}
.newtracking h2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  margin-bottom: 30px;
}

.AddTrackingNumber-content-modal-section {
  max-width: 872px;
  width: 100%;
  height: 266px;
  border-radius: 8px;
}
button.addtrack-btn {
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.react-responsive-modal-modal.AddTrackingNumber-content-modal-section {
  display: flex;
  justify-content: center;
  /* top: 295px; */
}

.newtracking {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center;
}
.AddTrackingNumber-content {
  display: flex !important;
  width: 100%;
}
.drop_modal_td::before {
  content: "";
  background: white;
  width: 14px;
  height: 14px;
  position: absolute;
  transform: rotate(45deg);
  left: -7px;
  border: 1px solid rgba(234, 236, 240, 1);
  top: 8px;
  z-index: 1;
}

.drop_modal_td {
  position: absolute;
  left: 90px;
  top: 0;
}
.modal-content_pre {
  background: white;
  border-radius: 8px;
  border: 1px solid rgba(234, 236, 240, 1);
  z-index: 9;
  position: relative;
}

.modal-content_pre .form-check-input:checked {
  background-color: rgba(146, 181, 58, 1);
  border-color: rgba(146, 181, 58, 1);
}

.modal-content_pre ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  min-width: 142px;
}

.modal-content_pre li {
  padding: 8px 16px;
  cursor: pointer;
}

.modal-content_pre li:hover {
  background-color: #f0f0f0;
}

.modal-content_pre input {
  width: 11px;
  height: 11px;
  margin: 0;
  padding: 6px;
  margin: 0 !important;
}
.modal-content_pre ul {
  display: flex;
  flex-direction: column;
}

.modal-content_pre .form-check {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 30px;
  padding: 5px;
  border-bottom: 1px solid rgba(234, 236, 240, 1);
  margin: 0;
}
.modal-order-status {
  position: relative;
}
.modal-overlay_pre {
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100%;
  height: 100%; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* z-index: 1000; */
}
.drop_modal_td {
  position: absolute;
  left: 90px;
  top: 0;
}
.manage-height {
  min-height: calc(100vh - 187px) !important;
}
.PendingRefilldata {
  display: flex;
  justify-content: space-between;
}
.PendingRefilldata .refill_content {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.setting_solution {
  max-width: 1165px !important;
}
.settings-form-provider input {
  width: 542px !important;
}
.SENDGRID_API_KEY_input input {
  width: 1104px !important;
}
form.settings-form.settings-form-provider.mb-4 {
  display: flex;
  gap: 10px;
}

/* new css */

.prescription.viewDetailsPre {
  margin: 0;
  padding: 0;
}
.prescription.PrescriptionInnerCompo {
  padding: 0;
}

.responsive-table {
  overflow: auto;
}

.responsive-table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Handle */
.responsive-table::-webkit-scrollbar-thumb {
  background: var(--primery_color);
  border-radius: 10px;
}
section.center_section-provider {
  padding: 0px 10px;
}

.Nav_row {
  padding: 0px 10px;
}
li.paginate_button.page-item.disabled span.page-link {
  height: 35px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}
.active > .page-link,
.page-link.active {
  background-color: var(--primery_color) !important;
  border-color: var(--primery_color) !important;
  box-shadow: 0px 0px 3px var(--primery_color) !important;
}
.header-tabimg img {
  display: none;
}
.responsive-table.prescription-table td {
  min-width: 115px;
}
.form-check.order-st input {
  cursor: pointer;
}
.form-check.order-st label {
  cursor: pointer;
}
.modal-content_pre .status-shipped:checked {
  background-color: #d9a900;
  border-color: #d9a900;
}
.modal-content_pre .status-pending:checked {
  background-color: #667085;
  border-color: #667085;
}
.mx-1 {
  max-width: 200px !important;
  word-wrap: break-word;
}
/* ======================= */
@media (max-width: 1535px) {
  .store_table.MyTeam_table .responsive-table th:first-child {
    max-width: 60px !important;
    width: 60px !important;
    min-width: 60px !important;
  }
  th.set-width.Icon-three {
    min-width: 290px;
  }
  .store_table th {
    min-width: 200px;
  }

  .store_table {
    overflow: auto;
  }

  .store_table::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Handle */
  .store_table::-webkit-scrollbar-thumb {
    background: var(--primery_color);
    border-radius: 10px;
  }
}
@media (max-width: 1200px) {
  /* new css */

  section.center_section.dashboard-section.provider_dashboard {
    min-height: 1040px !important;
  }

  .prac_main {
    margin-bottom: 30px;
  }
  .Nav_row {
    padding: 0px 0px;
  }

  .react-responsive-modal-modal.TestResultsAvailableModal {
    min-height: calc(100vh - 100px);
  }

  /* ====================== */
  .title {
    padding: 12px;
  }
  nav.square_nav_bar {
    padding: 16px 20px;
  }
  /* div#navbarTogglerDemo01 {
    padding: 20px 10px 10px 1px;
    position: absolute;
    top: 68px;
    background: #fff;
    width: 100%;
    left: -1px;
    padding: 20px 10px 10px 20px;
    border-bottom: 1px solid #ddd;
  } */

  /* .react-responsive-modal-modal.ProviderPopup-modal-section {
    left: 88px !important;
    top: 280px !important;
    width: 30% !important;
    border-radius: 5px;
    padding: 10px;
  }
  
  .react-responsive-modal-modal.Status-modal-section{
    left: 209px !important;
    top: 281px !important;
    width: 30% !important;
    border-radius: 5px;
    padding: 10px;
  } */

  .minus-margin label {
    width: 100%;
  }
  .minus-margin span {
    /* width: 100%; */
  }
  .minus-margin {
    display: flex;
    width: 100%;
    /* margin-bottom: 15px; */
    align-items: flex-start;
    flex-direction: column;
  }
  .change_password_card .subbtn {
    margin-left: 0%;
  }
  .magageheight {
    min-height: calc(100vh - 201px) !important;
  }
  .change_password_card-settings {
    height: auto;
  }

  .col.mx-1 h5,
  h2 {
    font-size: 14px;
  }
  .col-lg-6.image_column {
    display: none;
  }
  .login_card {
    height: auto;
  }
  .filter_div {
    max-width: 350px;
    width: 100%;
  }

  .react-responsive-modal-container {
    padding: 20px;
  }
  .ProviderDetails_card {
    padding: 0;
  }

  .row.details_heading {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .profile_store {
    display: flex;
    flex-wrap: wrap;
  }
  .top_marggin {
    margin-top: 60px;
  }
  .right-side {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 0px;
    max-width: 75%;
    width: 100%;
    justify-content: end;
    /* flex-direction: column; */
  }
  .back-div {
    width: 100%;
  }
  .DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_2.DateRangePickerInput__showClearDates.DateRangePickerInput__showClearDates_3 {
    min-width: 287px !important;
  }
  .DayPicker.DayPicker_1.DayPicker__horizontal.DayPicker__horizontal_2.DayPicker__withBorder.DayPicker__withBorder_3 {
    left: -95px !important;
  }
  .store_table {
    overflow: auto;
  }

  /* width */

  .store_table th {
    min-width: 200px;
  }
  .Icon-three {
    min-width: 300px !important;
  }

  .change_password_card .login-input-bx.minus-margin {
    display: block;
  }

  .filter_Prescription {
    width: -webkit-fill-available;
  }

  nav.square_nav_bar {
    margin: auto;
    border-radius: 0;
  }
  .breadcrumb {
    margin: 0 !important;
    height: 30px;
  }
  form.settings-form.settings-form-provider.mb-4 {
    flex-direction: column;
  }
  .settings-form-provider input {
    width: 100% !important;
  }
  form.settings-form.SENDGRID_API_KEY_input.mb-4 input {
    width: 100% !important;
  }
  .filterdata2 {
    top: 50px !important;
    left: unset;
  }
  .filterdata {
    right: 0;
  }
  .logo {
    display: none;
  }
  .header-tabimg img {
    width: 180px;
    margin-left: 20px;
    display: block;
  }
  .header-tabimg {
    display: flex;
  }
  /* p.Ordered.resend.me-2 {
  display: flex;
  max-width: 75px;
  margin: 10px 0;
} */
  .Prescr-textarea textarea {
    max-width: 92%;
  }
  .Practices-main .table tr td:nth-child(2) {
    max-width: 300px;
    word-wrap: break-word;
  }
  .prescriptioninner {
    padding: 24px 0px 24px 24px !important;
    gap: 20px;
  }
  .pre-left .refile-p {
    font-size: 16px;
  }
  .pre-left {
    min-width: 230px;
  }
  .store_table.MyTeam_table td:last-child {
    min-width: 285px;
  }
  .login-input-bx {
    max-width:unset;
  }
}

@media (max-width: 992px) {
  .main_menu_row {
    width: auto;
  }

  .user_profile {
    position: absolute;
    right: 30px;
    top: 15px;
    display: flex;
    gap: 34px;
  }

  .container-fluid.header_contain {
    flex-direction: column;
    justify-content: space-between;
    align-items: self-start;
    width: 100%;
  }
  a.navbar-brand img {
    margin-top: 20px;
  }
  .header-manage {
    margin-top: 20px;
  }
  .store_table.MyTeam_table th {
    min-width: 205px !important;
  }
  .responsive-table.prescription-table th {
    min-width: 210px !important;
  }
}

@media (max-width: 767px) {
  .user_profile {
    display: flex;
    gap: 30px;
  }

  .Addpatient-firstcard-top input.addpatient_input {
    height: 40px;
    width: 100%;
  }
  .Addpatient-firstcard-topright {
    width: 100%;
  }

  .test-result-card {
    padding-bottom: 0px;
  }
  .copy-div {
    margin-bottom: 0;
  }

  .newtracking h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 30px;
  }

  .modal-content p {
    font-weight: 600;
    font-size: 24px;
    line-height: 32.4px;
    text-align: Center;
  }

  .p-2 {
    padding: 0 !important;
  }
  .container-fluid.my_team_section {
    margin-bottom: 30px;
  }

  .user-modal-popup {
    position: absolute;
    width: max-content;
    background: white;
    right: 0;
    border-radius: 20px;
    border: 1px solid rgba(146, 181, 58, 0.3);
    padding: 12px 18px;
    top: 58px;
    transition: all ease-in-out 0.3s;
    width: max-content;
    right: 0;
  }

  ul.pagination {
    gap: 8px;
  }
  .store_table .active > .page-link,
  .page-link.active {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }

  button.page-link {
    min-width: 35px !important;
    height: 35px !important;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: "Inter", sans-serif !important;
    border: 1px solid var(--border-color);
    color: rgba(102, 112, 133, 1);
  }

  /* new css */
  .right-side .drop_select .filter_provider {
    width: 100%;
    min-width: 115px;
    justify-content: center;
  }
  /* .right-side .drop_select {
    min-width: 48%;
  } */
  .prescriptioninner.pre-main {
    flex-wrap: wrap;
    gap: 20px;
  }

  .filter_div {
    max-width: 100%;
    width: 100%;
  }

  .filter_div_ {
    width: 100%;
  }
  .DateRangePicker.DateRangePicker_1 {
    width: 100%;
  }

  /* ===================== */
  .filterdata2 {
    top: 45px !important;
    right: 0 !important;
    left: 135px;
  }
  .text-title {
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
    padding: 8px;
  }
  .back_arrow_forgot {
    margin-top: 0px;
  }
  .react-responsive-modal-modal.ProviderPopup-modal-section {
    left: -78px;
    top: 339px;
    width: 50%;
    border-radius: 5px;
    padding: 10px;
  }

  .react-responsive-modal-modal.Status-modal-section {
    left: 50px;
    top: 339px;
    width: 50%;
    border-radius: 5px;
    padding: 10px;
  }
  .react-responsive-modal-modal.Status-modal-section
    .react-responsive-modal-closeButton {
    display: none;
  }
  .minus-margin label {
    width: 100%;
  }

  .minus-margin {
    display: flex;
    width: 100%;
    /* margin-bottom: 15px; */
    align-items: flex-start;
    flex-direction: column;
  }
  .change_password_card .subbtn {
    margin-left: 0%;
  }
  .magageheight {
    min-height: calc(100vh - 201px) !important;
  }
  .change_password_card-settings {
    height: auto;
  }

  .col.mx-1 h5,
  h2 {
    font-size: 14px;
  }
  .store_table div#example_paginate {
    /* margin-left: 95px; */
  }
  /* .scroll-popup {
    max-height: 550px;
    overflow: auto;
} */
  .prescription {
    margin: 20px 20px;
  }

  .prescriptioninner {
    margin: 10px 10px;
    padding: 13px;
  }
  .trackingNum {
    text-align: end;
  }
  .preseinner p {
    font-size: 16px;
  }

  .chat {
    margin-bottom: 30px;
  }

  .col-lg-6.image_column {
    display: none;
  }

  .login_card {
    height: auto;
    margin-top: 40px;
  }
  .stepper-div {
    margin: 30px auto 0px auto;
  }
  .prac_main {
    margin-bottom: 30px !important;
  }
  .DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_2.DateRangePickerInput__showClearDates.DateRangePickerInput__showClearDates_3 {
    min-width: 330px !important;
    border: 1px solid rgba(208, 213, 221, 1) !important;
    border-radius: 8px !important;
    width: 100% !important;
  }
  .login_section {
    padding: 40px 20px;
    background-color: white;
  }

  .invite_profile-content .image-invite {
    max-width: -webkit-fill-available;
  }
  .content-invite h2 {
    font-size: 24px;
    line-height: 32px;
  }
  .contetnof {
    padding: 0px;
  }
  .resend_modal {
    height: auto;
  }

  .modal-content {
    top: 0;
  }
  .invite_profile-content .content-invite {
    padding: 20px;
  }
  .image-invite img {
    height: 100%;
    padding: 12px 40px 0px 40px;
    width: 100%;
    object-fit: contain;
    max-width: 250px;
    margin: auto;
    display: block;
  }

  .invite_profile-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    flex-direction: column;
  }

  .row.details_heading {
    display: grid;
    grid-template-columns: 1fr;
  }
  .change_password_card .login-input-bx.minus-margin {
    display: block;
  }

  .login_form {
    /* max-width: 550px; */
    border-radius: 10px !important;
    padding: 0px 0px !important;
    margin: 30px auto;
  }
  nav.square_nav_bar {
    margin: 1px auto 0px !important;
    border-radius: 0px;
    padding: 20px 10px 10px 10px;
  }

  .user-modal-section {
    right: 1px !important;
    top: 64px;
  }
  .orders-modal-section {
    top: 245px !important;
    left: 116px;
  }

  .login-heading-image img {
    max-width: 95px;
  }

  div#example_length {
    position: absolute;
    top: 150px !important;
  }

  .rememberr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  select.form-select {
    max-width: 110px;
  }
  .title {
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  .title h2 {
    margin-bottom: 10px;
  }
  .store_table td {
    min-width: 150px;
  }

  .login_form {
    padding: 0px 15px !important;
  }
  .store_table.MyTeam_table th {
    min-width: 205px !important;
  }
  .responsive-table.prescription-table th {
    min-width: 210px !important;
  }
  .logo {
    display: block;
  }
  .header-tabimg img {
    display: none;
  }
  .right-side {
    max-width: 99%;
  }
  .mx-1 {
    max-width: 300px !important;
    word-wrap: break-word;
  }
  .prescriptioninner {
    padding: 24px 0px 24px 24px !important;
    gap: 20px;
  }
  .pre-left .refile-p {
    font-size: 16px;
  }
  .pre-left {
    min-width: 230px;
  }
  .store_table.MyTeam_table td:last-child {
    min-width: 285px;
  }
  .signup-contant p {
    padding: 0 0px;
}
.signup_main .login_section {
  padding: 35px 20px 0px 20px;
}
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1616px;
  }
}

.addpatient-dobleinput {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between input and button */
}

.add-btn {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-btn:hover {
  background-color: #0056b3;
}


.address-section {
  margin: 20px 0;
  padding: 20px; 
  border-top: 2px solid #ccc; 
  border-bottom: 2px solid #ccc; 
}

.addpatient-dobleinput.last_address {
  justify-content: space-between;
  align-items: end;
}

.addpatient-dobleinput.last_address .login-input-bx {
  margin: 0 !important;
}
.addpatient-dobleinput.last_address button.validate-address-btn {
  margin: 0;
}

.login-input-bx {
  position: relative;
}
.login-input-bx p.error {
  position: absolute;
}

.message_bar_top {
  max-width: 1597px;
  margin: auto;
  font-size: 14px;
  padding: 12px;
  border: 1px solid #92b53a;
  border-radius: 12px;
  background: #92b53a2e;
  margin-bottom: 10px;
  color: red;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.message_bar_top div {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
}
.message_bar_top p {
  margin: 0;
}
.message_bar_top + .breadcrumb {
  height: auto;
}


.bottom-div-padding {
  height: 80px;
}

